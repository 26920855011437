const common = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.nr-data.net"] },
  },
  loader: {
    licenseKey: "NRJS-aba8d1ef5fc874db625",
    accountID: "3661855",
    trustKey: "3661855",
  },
  info: {
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    sa: 1,
  },
};

export const configs = {
  dev: {
    init: common.init,
    loader: {
      ...common.loader,
      agentID: "601397080",
      applicationID: "601397080",
    },
    info: {
      ...common.info,
      licenseKey: "NRJS-aba8d1ef5fc874db625",
      applicationID: "601397080",
      sa: 1,
    },
  },
  uat: {
    init: common.init,
    loader: {
      ...common.loader,
      agentID: "601397092",
      applicationID: "601397092",
    },
    info: {
      ...common.info,
      licenseKey: "NRJS-aba8d1ef5fc874db625",
      applicationID: "601397092",
    },
  },
};
