import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InventoryAsset } from "./generatedApi";

export interface AssetWcuState {
  wcu?: InventoryAsset["wcu"];
  assetId?: string;
}

const initialState: AssetWcuState = {};

const AssetWcuSlice = createSlice({
  name: "AssetWcuSlice",
  initialState,
  reducers: {
    setAssetWcu: (
      state,
      action: PayloadAction<
        { wcu: InventoryAsset["wcu"]; assetId: string } | undefined
      >
    ) => {
      state.assetId = action.payload?.assetId;
      state.wcu = action.payload?.wcu;
    },
  },
});

export const { setAssetWcu } = AssetWcuSlice.actions;
export default AssetWcuSlice.reducer;
