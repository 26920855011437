import i18n, { DefaultTFuncReturn } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import english from "../public/locales/en/translation.json";

export type i18nString = string | DefaultTFuncReturn;

const resources = {
  en: { common: english },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources,
    ns: ["common"],
    defaultNS: "common",
    returnNull: false,
  })
  .catch(console.error);

export default i18n;
