// Internal URLs
export const LOGIN_REDIRECT_URL = "/api/login";
export const LOGOUT_REDIRECT_URL = "/api/logout";
export const LOGGED_OUT_URL = "/loggedout";
export const PROFILE_PAGE_URL = "/profile";
export const ACCOUNT_PAGE_URL = "/account";
export const ADMIN_PAGE_URL = "/admin";
export const TAG_CREATE_PAGE_URL = "/admin/tag-library/tag-create";
export const TAG_ASSIGN_PAGE_URL = "/admin/tag-library/tag-assign";
export const TAG_SUMMARY_PAGE_URL = "/admin/tag-library/tag-summary";
export const TAG_LIBRARY_PAGE_URL = "/admin/tag-library";
export const TAG_IMPORT_PAGE_URL = "/admin/tag-library/tag-import";
export const EVENT_PAGE_URL = "/admin/events";
export const VIEWS_PAGE_URL = "/admin/views";
export const HOME_PAGE_URL = "/";
export const WIDGET_PAGE_URL = "/widgets";
export const ASSETS_PAGE_URL = "/assets/:id";
export const COLLECTION_URL = "/collections/:id";
export const LOGS_PAGE_URL = "/admin/logs/:id";
export const VIEW_PAGE_URL = "/views/:id";
export const INTEGRATIONS_PAGE_URL = "/admin/integrations";
export const ORG_CHANGE_SAFE_URLS = new Set([
  HOME_PAGE_URL,
  PROFILE_PAGE_URL,
  ACCOUNT_PAGE_URL,
]);

// External URLs
export const SUPPORT_PAGE_URL = "support.infraview.app";
export const FULL_SUPPORT_PAGE_URL = "https://" + SUPPORT_PAGE_URL;
