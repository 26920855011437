import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { Provider } from "react-redux";
import store from "./store";

import { useTranslation } from "react-i18next";
import SpinnerWithText from "./components/SpinnerWithText";
import { configs as newrelic } from "./constants/newrelic";
import {
  ACCOUNT_PAGE_URL,
  ADMIN_PAGE_URL,
  ASSETS_PAGE_URL,
  COLLECTION_URL,
  EVENT_PAGE_URL,
  INTEGRATIONS_PAGE_URL,
  LOGGED_OUT_URL,
  LOGS_PAGE_URL,
  PROFILE_PAGE_URL,
  TAG_ASSIGN_PAGE_URL,
  TAG_CREATE_PAGE_URL,
  TAG_IMPORT_PAGE_URL,
  TAG_LIBRARY_PAGE_URL,
  TAG_SUMMARY_PAGE_URL,
  VIEW_PAGE_URL,
  VIEWS_PAGE_URL,
  WIDGET_PAGE_URL,
} from "./constants/urls";
import "./i18n";
import "./styles/main.scss";

interface SuspenseWrapperProps {
  path: string;
}
const LayoutSuspenseWrapper = (props: SuspenseWrapperProps) => {
  const { t } = useTranslation();
  const LazyComponent = React.lazy(() => import(`./layout/${props.path}.tsx`));

  return (
    <Suspense fallback={<SpinnerWithText i18nTextKey="common.loading" />}>
      <LazyComponent />
    </Suspense>
  );
};
/* 
Dynamic import works with one level deep path. While pages/Profile.tsx, pages/Account.tsx will work
pages/admin/index.tsx or pages/admin/landing.tsx will not work
for more info https://vitejs.dev/guide/features.html#dynamic-import

 */
const PageSuspenseWrapper = (props: SuspenseWrapperProps) => {
  const { t } = useTranslation();
  const LazyComponent = React.lazy(() => import(`./pages/${props.path}.tsx`));

  return (
    <Suspense fallback={<SpinnerWithText i18nTextKey="common.loading" />}>
      <LazyComponent />
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    path: LOGGED_OUT_URL,
    element: <PageSuspenseWrapper path="LoggedOut" />,
  },
  {
    path: "/",
    element: <LayoutSuspenseWrapper path="DefaultLayout" />,
    children: [
      {
        path: "/",
        element: <PageSuspenseWrapper path="Main" />,
      },
      {
        path: PROFILE_PAGE_URL,
        element: <PageSuspenseWrapper path="Profile" />,
      },
      {
        path: ACCOUNT_PAGE_URL,
        element: <PageSuspenseWrapper path="Account" />,
      },
      {
        path: ADMIN_PAGE_URL,
        element: <PageSuspenseWrapper path="Admin" />,
      },
      {
        path: TAG_CREATE_PAGE_URL,
        element: <PageSuspenseWrapper path="CreateTags" />,
      },
      {
        path: TAG_ASSIGN_PAGE_URL,
        element: <PageSuspenseWrapper path="AssignTags" />,
      },
      {
        path: TAG_SUMMARY_PAGE_URL,
        element: <PageSuspenseWrapper path="SummaryTags" />,
      },
      {
        path: TAG_IMPORT_PAGE_URL,
        element: <PageSuspenseWrapper path="TagImport" />,
      },
      {
        path: TAG_LIBRARY_PAGE_URL,
        element: <PageSuspenseWrapper path="TagLibrary" />,
      },
      {
        path: WIDGET_PAGE_URL,
        element: <PageSuspenseWrapper path="Widgets" />,
      },
      {
        path: LOGS_PAGE_URL,
        element: <PageSuspenseWrapper path="AuditLogs" />,
      },
      {
        path: VIEW_PAGE_URL,
        element: <PageSuspenseWrapper path="View" />,
      },
      {
        path: ASSETS_PAGE_URL,
        element: <PageSuspenseWrapper path="Asset" />,
      },
      {
        path: COLLECTION_URL,
        element: <PageSuspenseWrapper path="Collection" />,
      },
      {
        path: EVENT_PAGE_URL,
        element: <PageSuspenseWrapper path="Event" />,
      },
      {
        path: VIEWS_PAGE_URL,
        element: <PageSuspenseWrapper path="Views" />,
      },
      {
        path: INTEGRATIONS_PAGE_URL,
        element: <PageSuspenseWrapper path="Integrations" />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

/* c8 ignore next 5 */
if (import.meta.env.VITE_STAGE in newrelic) {
  console.log("Enabling Newrelic Agent");
  const options = newrelic[import.meta.env.VITE_STAGE];
  new BrowserAgent(options); // NOSONAR
}
