/**
 * Truncates string to specified length and adds a elipsis suffix
 * if truncated string is smaller than suffix, just returns the suffix
 * @param string
 * @param maxLength
 * @returns
 */
export const truncateString = (string: string, maxLength: number) => {
  const suffix = "...";

  if (string.length <= maxLength) {
    return string;
  }
  if (maxLength <= suffix.length) {
    return suffix;
  }

  return string.substring(0, maxLength - suffix.length) + suffix;
};

export const includeString = (strg: string, ...args: string[]) => {
  return (
    args.filter((str: any) => strg.indexOf(str) > -1).length === args.length
  );
};
