import { isEmpty } from "@/utils";
import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import {
  Collection,
  DashboardTimeHorizon,
  RatingsOptions,
  Tag,
} from "./generatedApi";

const DEFAULT_TIME_HORIZON = "Last 30 Days";

export type DropdownOption = {
  value: string;
  label: string;
};

export interface DashboardState {
  tags: DropdownOption[];
  tagInput?: DropdownOption[]; //tag clone
  collectionInput?: DropdownOption[]; //collection clone
  collections: DropdownOption[];
  isModified: boolean; //control apply filter button
  canSaveView?: boolean; //controll save view button
  enableClearAllFilters: boolean; //controll reset all filter button
  defaultTags: Tag[]; //tags for the saved view
  defaultCollections: Collection[]; //collection for the saved view
  timeHorizon: DashboardTimeHorizon;
  infraRating?: RatingsOptions;
  applyFilter?: boolean;
  queryCacheKeys?: any[]; // track requests from the cache
  queryEndpoints: any[]; //track request from the api calls
  filterParams?: {
    collectionIds?: string[];
    tagIds?: string[];
    timeHorizon?: DashboardTimeHorizon;
    organizationId?: string;
    id?: string;
  };
}

const initialState: DashboardState = {
  tags: [],
  collections: [],
  isModified: false,
  defaultTags: [],
  defaultCollections: [],
  timeHorizon: DEFAULT_TIME_HORIZON,
  canSaveView: false,
  enableClearAllFilters: false,
  queryEndpoints: [],
  infraRating: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearFilters: (state) => {
      state.tags = [];
      state.collections = [];
      state.collectionInput = [];
      state.isModified = false;
      state.canSaveView = false;
      state.enableClearAllFilters = false;
    },
    resetAllFilters: (state) => {
      clearFilters();
    },

    toggleSaveViewButton: (state) => {
      state.canSaveView = !state.canSaveView;
    },

    clearTagsInputValues: (state) => {
      state.tagInput = [];
      state.isModified = false;
    },
    clearCollectionInputValues: (state) => {
      state.collectionInput = [];
      state.isModified = false;
    },

    applyFilter: (state) => {
      const currentState = current(state);
      state.canSaveView =
        !isEmpty(currentState?.tagInput) ||
        !isEmpty(currentState?.collectionInput);
      state.isModified = false;
      state.collections = [...(state.collectionInput ?? [])];
      state.tags = [...(state.tagInput ?? [])];
      state.applyFilter = true;
    },

    setTags: (
      state,
      action: PayloadAction<{
        selectedTags: DropdownOption[];
        disableFilterButton?: boolean;
      }>
    ) => {
      state.tagInput = action.payload.selectedTags;
      state.isModified =
        !action.payload.disableFilterButton &&
        (state.tags?.length > 0 || state.tagInput?.length > 0);
      state.enableClearAllFilters = true;
      state.canSaveView = false;
    },

    setCollections: (
      state,
      action: PayloadAction<{
        selectedCollections: DropdownOption[];
        disableFilterButton?: boolean;
      }>
    ) => {
      state.collectionInput = action.payload.selectedCollections;
      state.isModified =
        !action.payload.disableFilterButton &&
        (state.collections?.length > 0 || state.collectionInput?.length > 0);
      state.enableClearAllFilters = true;
      state.canSaveView = false;
    },
    setDefaults: (
      state,
      action: PayloadAction<{
        collections: any[];
        tags: any[];
      }>
    ) => {
      state.defaultCollections = action.payload.collections;
      state.defaultTags = action.payload.tags;
      state.collections = action.payload.collections && [
        ...action.payload.collections.map((item) => ({
          value: item?.id,
          label: item?.name,
        })),
      ];
      state.tags = action.payload.tags && [
        ...action.payload.tags.map((item) => ({
          value: item?.id,
          label: item?.tagId,
        })),
      ];
      state.canSaveView = false;
      state.isModified = false;
      state.applyFilter = true;
    },
    setTimeHorizon: (state, action: PayloadAction<DashboardTimeHorizon>) => {
      state.timeHorizon = action.payload;
    },
    resetTimeHorizon: (state) => {
      state.timeHorizon = DEFAULT_TIME_HORIZON;
    },
    setInfraRatings: (
      state,
      action: PayloadAction<RatingsOptions | undefined>
    ) => {
      state.infraRating = action.payload;
    },
    setQueryCacheKeys: (state, action: PayloadAction<string>) => {
      const currentState = current(state);
      let queryCacheKeys = new Set(currentState.queryCacheKeys);
      queryCacheKeys?.add(action.payload);
      state.queryCacheKeys = [...queryCacheKeys];
    },
    setQueryEndpoints: (
      state,
      action: PayloadAction<{ key?: string; value?: boolean }>
    ) => {
      const queryEndpoints = [...current(state).queryEndpoints];
      if (isEmpty(action.payload)) {
        state.queryEndpoints = [];
      } else {
        queryEndpoints.push(action.payload);
        state.queryEndpoints = [...new Set(queryEndpoints)];
      }
    },

    clearQueryCacheKeys: (state) => {
      state.queryCacheKeys = [];
    },
  },
});

export const {
  clearFilters,
  applyFilter,
  setTags,
  setCollections,
  setDefaults,
  setTimeHorizon,
  resetTimeHorizon,
  setInfraRatings,
  clearTagsInputValues,
  clearCollectionInputValues,
  toggleSaveViewButton,
  resetAllFilters,
  setQueryCacheKeys,
  clearQueryCacheKeys,
  setQueryEndpoints,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
