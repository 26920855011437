import { HOME_PAGE_URL, ORG_CHANGE_SAFE_URLS } from "@/constants/urls";
import { BusinessEventForEdit } from "@/modals/addEvent/AddEventModal";
import { BusinessEventWithDateTime } from "@/pages/Event";
import { AssetSystemAttribute } from "@/store/generatedApi";
import { DateTime } from "luxon";
import { NavigateFunction } from "react-router-dom";

export const isEmpty = (obj: unknown) => {
  if (obj === undefined || obj === null || obj === "") {
    return true;
  }
  if (typeof obj === "object" && Object.keys(obj).length === 0) {
    return true;
  }
  return false;
};
export const deepCheckIsEmptyObject = (obj: object): boolean => {
  if (isEmpty(obj)) return true;
  else
    return Object.values(obj).every((value: any) => {
      if (value === undefined) return true;
      else return isEmpty(value);
    });
};
export const uploadToS3 = (
  setPercent: (num: number) => void,
  file: File,
  url: string
) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100);
        setPercent(percent > 10 ? percent : 10);
      }
    };

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        xhr.status === 200 ? resolve(xhr.responseText) : reject(xhr.statusText);
      }
    };

    xhr.open("PUT", url);
    xhr.setRequestHeader("Content-Type", "text/csv");
    xhr.setRequestHeader(
      "Content-Disposition",
      `attachment; filename="${file.name}"`
    );
    xhr.send(file);
  });
};

export function* generateId() {
  let id = 0;
  while (true) {
    yield id++;
  }
}

export function fireAndForget(func: (...args: any[]) => Promise<void>) {
  return (...args: any[]) => {
    func(...args).catch((e) => {
      console.error("Error on asynchronous action");
      console.error(e);
    });
  };
}

export function validateRegex(
  value?: string,
  regexString: string = "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
): boolean {
  if (!value) return false;
  let regex = new RegExp(regexString);
  return regex.test(value);
}

export function getLocalDateTimeString(date: Date): string {
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  })}`;
}

export function getLocalDateTimeStringFromDateTime(date: DateTime): string {
  const dateFormat = "MM/dd/yyyy hh:mm a ZZZZ";
  return date.toFormat(dateFormat);
}

export function triggerFileDownload(url: string) {
  window.location.href = url;
}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}
export const getPageUrl = (): string => {
  const location = window.location.pathname?.split("/") ?? [];
  return location[location.length - 1];
};

export const getJsonPath = (link: string): string => {
  let jsonPath = "";
  if (link.includes(".")) {
    const splitLink = link.split(".");
    jsonPath = `$..[?(@.page=='${splitLink?.[0]}')].links[?(@.section=='${splitLink?.[1]}')].link`;
  } else {
    const page = getPageUrl();
    jsonPath = `$..[?(@.page=='${page}')].links[?(@.section=='${link}')].link`;
  }
  return jsonPath;
};
export const getOffset = (timeZone: string): string | undefined => {
  const timeZoneName = Intl.DateTimeFormat("en", {
    timeZoneName: "short",
    timeZone,
  })
    .formatToParts()
    ?.find((i) => i.type === "timeZoneName")?.value;

  return timeZoneName;
};

export const SELECTED_ORGANIZATION_KEY = "selected-organization";
export const SELECTED_ACCOUNT_KEY = "selected-account";
export const clearStorage = () => window.localStorage.clear();
export const setLocalStorage = (value: string, key: string): any =>
  window.localStorage.setItem(key, value);
export const getLocalStorage = (key: string) =>
  typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem(key) as string)
    : null;

export const eventsAreTheSame = (
  eventWithImpact: BusinessEventWithDateTime,
  eventForEdit: BusinessEventForEdit
) => {
  return eventForEdit.startDate && eventForEdit.endDate
    ? eventWithImpact?.name === eventForEdit?.name &&
        eventForEdit?.startDate &&
        eventForEdit?.endDate &&
        eventWithImpact.startDate &&
        eventWithImpact.endDate &&
        eventWithImpact.startDate.diff(eventForEdit.startDate).milliseconds ===
          0 &&
        eventWithImpact.endDate.diff(eventForEdit.endDate).milliseconds === 0 &&
        eventWithImpact?.description === eventForEdit?.description
    : false;
};

export function sortByKey<T>(key: keyof T) {
  return (a: T, b: T) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  };
}

export function getAttributeValueByName(
  attributes: AssetSystemAttribute[] | undefined,
  attributeName: string
): string | undefined {
  const attribute = attributes?.find((attr) => attr.name === attributeName);
  return attribute?.value;
}

export const mapEventCountForChart = (eventCounts: number[]) => {
  return eventCounts.map((count) => (count ? 0 : NaN));
};

export const getSymbolForCurrency = (currency: string | null) => {
  switch (currency) {
    case "USD":
    case "CAD":
    default:
      return "$";
  }
};

export const homepageRedirect = (
  currentUrl: string,
  navigate: NavigateFunction
) => {
  if (ORG_CHANGE_SAFE_URLS.has(currentUrl)) {
    return;
  }

  navigate(HOME_PAGE_URL);
};
