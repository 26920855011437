import { emptyApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAccount: build.mutation<
      CreateAccountApiResponse,
      CreateAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts`,
        method: "POST",
        body: queryArg.createAccountParams,
      }),
    }),
    getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/accounts`,
        params: { authClientId: queryArg.authClientId },
      }),
    }),
    updateAccount: build.mutation<
      UpdateAccountApiResponse,
      UpdateAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/${queryArg.id}`,
        method: "PUT",
        body: queryArg.updateAccountParams,
      }),
    }),
    deleteAccount: build.mutation<
      DeleteAccountApiResponse,
      DeleteAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/accounts/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getAccount: build.query<GetAccountApiResponse, GetAccountApiArg>({
      query: (queryArg) => ({ url: `/api/v1/accounts/${queryArg.slug}` }),
    }),
    getAssets: build.query<GetAssetsApiResponse, GetAssetsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/assets`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          tagIds: queryArg.tagIds,
          collectionIds: queryArg.collectionIds,
          timeHorizon: queryArg.timeHorizon,
          includeRatingData: queryArg.includeRatingData,
        },
      }),
    }),
    upsertAsset: build.mutation<UpsertAssetApiResponse, UpsertAssetApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/assets`,
        method: "PUT",
        body: queryArg.upsertAssetParams,
      }),
    }),
    getAsset: build.query<GetAssetApiResponse, GetAssetApiArg>({
      query: (queryArg) => ({ url: `/api/v1/assets/${queryArg.id}` }),
    }),
    getAssetSystemUtilization: build.query<
      GetAssetSystemUtilizationApiResponse,
      GetAssetSystemUtilizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/system-utilization`,
        headers: { organizationId: queryArg.organizationId },
        params: { timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getAssetsForBusinessEventImpact: build.query<
      GetAssetsForBusinessEventImpactApiResponse,
      GetAssetsForBusinessEventImpactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/business-event/impact`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    getAssetSystemAttributes: build.query<
      GetAssetSystemAttributesApiResponse,
      GetAssetSystemAttributesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/system-attributes`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    getAssetWorkload: build.query<
      GetAssetWorkloadApiResponse,
      GetAssetWorkloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/workload`,
        headers: { organizationId: queryArg.organizationId },
        params: { timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getAssetCostAnalysis: build.query<
      GetAssetCostAnalysisApiResponse,
      GetAssetCostAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/cost-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: { timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getAssetWorkloadGapAnalysis: build.query<
      GetAssetWorkloadGapAnalysisApiResponse,
      GetAssetWorkloadGapAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/workload-gap-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: { timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getAssetSystemRatings: build.query<
      GetAssetSystemRatingsApiResponse,
      GetAssetSystemRatingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/${queryArg.id}/system-ratings`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    updateActiveStatus: build.mutation<
      UpdateActiveStatusApiResponse,
      UpdateActiveStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/assets/update-active-status`,
        method: "POST",
        body: queryArg.updateActiveStatusParams,
      }),
    }),
    authHealth: build.query<AuthHealthApiResponse, AuthHealthApiArg>({
      query: () => ({ url: `/api/v1/auth/health` }),
    }),
    getToken: build.mutation<GetTokenApiResponse, GetTokenApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/auth/token`,
        method: "POST",
        body: queryArg.getTokenParams,
      }),
    }),
    list: build.query<ListApiResponse, ListApiArg>({
      query: () => ({ url: `/api/v1` }),
    }),
    getBusinessEvents: build.query<
      GetBusinessEventsApiResponse,
      GetBusinessEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    addBusinessEvent: build.mutation<
      AddBusinessEventApiResponse,
      AddBusinessEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events`,
        method: "POST",
        body: queryArg.addBusinessEventParams,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    getBusinessEvent: build.query<
      GetBusinessEventApiResponse,
      GetBusinessEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events/${queryArg.id}`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    updateBusinessEvent: build.mutation<
      UpdateBusinessEventApiResponse,
      UpdateBusinessEventApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateBusinessEventParams,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    upsertBusinessEventImpact: build.mutation<
      UpsertBusinessEventImpactApiResponse,
      UpsertBusinessEventImpactApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events/impact`,
        method: "PUT",
        body: queryArg.upsertBusinessEventImpactParams,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    getBusinessEventsForTarget: build.query<
      GetBusinessEventsForTargetApiResponse,
      GetBusinessEventsForTargetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/business-events/target/${queryArg.id}`,
        headers: { organizationId: queryArg.organizationId },
        params: { startDate: queryArg.startDate, endDate: queryArg.endDate },
      }),
    }),
    getCollections: build.query<
      GetCollectionsApiResponse,
      GetCollectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    createCollection: build.mutation<
      CreateCollectionApiResponse,
      CreateCollectionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections`,
        method: "POST",
        body: queryArg.createCollectionParams,
      }),
    }),
    getCollection: build.query<GetCollectionApiResponse, GetCollectionApiArg>({
      query: (queryArg) => ({ url: `/api/v1/collections/${queryArg.id}` }),
    }),
    getCollectionSystemUtilization: build.query<
      GetCollectionSystemUtilizationApiResponse,
      GetCollectionSystemUtilizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections/${queryArg.id}/system-utilization`,
        headers: { organizationId: queryArg.organizationId },
        params: { tagIds: queryArg.tagIds, timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getCollectionWorkload: build.query<
      GetCollectionWorkloadApiResponse,
      GetCollectionWorkloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections/${queryArg.id}/workload`,
        headers: { organizationId: queryArg.organizationId },
        params: { tagIds: queryArg.tagIds, timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getCollectionSummary: build.query<
      GetCollectionSummaryApiResponse,
      GetCollectionSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections/${queryArg.id}/summary`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    getCollectionCostAnalysis: build.query<
      GetCollectionCostAnalysisApiResponse,
      GetCollectionCostAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections/${queryArg.id}/cost-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: { tagIds: queryArg.tagIds, timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getCollectionWorkloadGapAnalysis: build.query<
      GetCollectionWorkloadGapAnalysisApiResponse,
      GetCollectionWorkloadGapAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/collections/${queryArg.id}/workload-gap-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: { tagIds: queryArg.tagIds, timeHorizon: queryArg.timeHorizon },
      }),
    }),
    updateAwsIntegration: build.mutation<
      UpdateAwsIntegrationApiResponse,
      UpdateAwsIntegrationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/integrations/aws`,
        method: "PUT",
        body: queryArg.updateAwsIntegrationParams,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    sidebarData: build.query<SidebarDataApiResponse, SidebarDataApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/meta/sidebar`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    updateFavorite: build.mutation<
      UpdateFavoriteApiResponse,
      UpdateFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/meta/sidebar/favorite`,
        method: "PUT",
        body: queryArg.iSidebarItem,
      }),
    }),
    getOrganizations: build.query<
      GetOrganizationsApiResponse,
      GetOrganizationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations`,
        params: { accountSlug: queryArg.accountSlug },
      }),
    }),
    getOrganizationWorkload: build.query<
      GetOrganizationWorkloadApiResponse,
      GetOrganizationWorkloadApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/workload`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          tagIds: queryArg.tagIds,
          collectionIds: queryArg.collectionIds,
          timeHorizon: queryArg.timeHorizon,
        },
      }),
    }),
    getOrganizationCostAnalysis: build.query<
      GetOrganizationCostAnalysisApiResponse,
      GetOrganizationCostAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/cost-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          tagIds: queryArg.tagIds,
          collectionIds: queryArg.collectionIds,
          timeHorizon: queryArg.timeHorizon,
        },
      }),
    }),
    getOrganizationWorkloadGapAnalysis: build.query<
      GetOrganizationWorkloadGapAnalysisApiResponse,
      GetOrganizationWorkloadGapAnalysisApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/workload-gap-analysis`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          tagIds: queryArg.tagIds,
          collectionIds: queryArg.collectionIds,
          timeHorizon: queryArg.timeHorizon,
        },
      }),
    }),
    getOrganizationBusinessOverview: build.query<
      GetOrganizationBusinessOverviewApiResponse,
      GetOrganizationBusinessOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/business-overview`,
        headers: { organizationId: queryArg.organizationId },
        params: { timeHorizon: queryArg.timeHorizon },
      }),
    }),
    getOrganizationRatings: build.query<
      GetOrganizationRatingsApiResponse,
      GetOrganizationRatingsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/system-ratings`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          collectionIds: queryArg.collectionIds,
          tagIds: queryArg.tagIds,
          timeHorizon: queryArg.timeHorizon,
        },
      }),
    }),
    getOrganizationSystemUtilization: build.query<
      GetOrganizationSystemUtilizationApiResponse,
      GetOrganizationSystemUtilizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/organizations/system-utilization`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          tagIds: queryArg.tagIds,
          collectionIds: queryArg.collectionIds,
          timeHorizon: queryArg.timeHorizon,
        },
      }),
    }),
    getTagUploadUrl: build.query<
      GetTagUploadUrlApiResponse,
      GetTagUploadUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/s3/tag-upload/${queryArg.fileName}`,
      }),
    }),
    getTagDownloadUrl: build.query<
      GetTagDownloadUrlApiResponse,
      GetTagDownloadUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/s3/tag-download/${queryArg.fileName}`,
      }),
    }),
    userService: build.query<UserServiceApiResponse, UserServiceApiArg>({
      query: () => ({ url: `/api/v1/stubs/users/service` }),
    }),
    adminService: build.query<AdminServiceApiResponse, AdminServiceApiArg>({
      query: () => ({ url: `/api/v1/stubs/admin/service` }),
    }),
    systemAdminService: build.query<
      SystemAdminServiceApiResponse,
      SystemAdminServiceApiArg
    >({
      query: () => ({ url: `/api/v1/stubs/system/service` }),
    }),
    validateTagFromFile: build.query<
      ValidateTagFromFileApiResponse,
      ValidateTagFromFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/validate/import`,
        params: {
          organizationId: queryArg.organizationId,
          fileName: queryArg.fileName,
        },
      }),
    }),
    importTagFromFile: build.mutation<
      ImportTagFromFileApiResponse,
      ImportTagFromFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/import`,
        method: "POST",
        params: {
          organizationId: queryArg.organizationId,
          fileName: queryArg.fileName,
        },
      }),
    }),
    getPastImportLogs: build.query<
      GetPastImportLogsApiResponse,
      GetPastImportLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/import`,
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    batchCreateTag: build.mutation<
      BatchCreateTagApiResponse,
      BatchCreateTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/batch`,
        method: "POST",
        body: queryArg.batchCreateTagsParams,
      }),
    }),
    batchAssignTag: build.mutation<
      BatchAssignTagApiResponse,
      BatchAssignTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/batchAssignTags`,
        method: "POST",
        body: queryArg.batchAssignTagsParams,
      }),
    }),
    assignSystemTag: build.mutation<
      AssignSystemTagApiResponse,
      AssignSystemTagApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tags/assignSystemTag`,
        method: "POST",
        body: queryArg.assignSystemTagParams,
      }),
    }),
    getTags: build.query<GetTagsApiResponse, GetTagsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/tags`,
        params: {
          organizationId: queryArg.organizationId,
          assetId: queryArg.assetId,
          collectionIds: queryArg.collectionIds,
          includeSystemTags: queryArg.includeSystemTags,
          tagIds: queryArg.tagIds,
          includeTagAssets: queryArg.includeTagAssets,
        },
      }),
    }),
    updateTag: build.mutation<UpdateTagApiResponse, UpdateTagApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/tags/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.omitUpdateTagParamsId,
      }),
    }),
    getTagsEvents: build.query<GetTagsEventsApiResponse, GetTagsEventsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/tags/getTagsEvents`,
        headers: { organizationId: queryArg.organizationId },
      }),
    }),
    createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "POST",
        body: queryArg.createUserParams,
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.omitUpdateUserParamsUserId,
      }),
    }),
    getProfile: build.query<GetProfileApiResponse, GetProfileApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        params: {
          organizationId: queryArg.organizationId,
          accountSlug: queryArg.accountSlug,
        },
      }),
    }),
    getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
      query: (queryArg) => ({ url: `/api/v1/users/details/${queryArg.id}` }),
    }),
    acceptanceStatus: build.query<
      AcceptanceStatusApiResponse,
      AcceptanceStatusApiArg
    >({
      query: () => ({ url: `/api/v1/users/acceptance-status` }),
    }),
    userAcceptanceData: build.query<
      UserAcceptanceDataApiResponse,
      UserAcceptanceDataApiArg
    >({
      query: () => ({ url: `/api/v1/users/userAcceptanceData` }),
    }),
    saveUserCustomData: build.mutation<
      SaveUserCustomDataApiResponse,
      SaveUserCustomDataApiArg
    >({
      query: () => ({
        url: `/api/v1/users/saveUserCustomData`,
        method: "POST",
      }),
    }),
    getEnrollMfaUrl: build.query<
      GetEnrollMfaUrlApiResponse,
      GetEnrollMfaUrlApiArg
    >({
      query: () => ({ url: `/api/v1/users/enroll-mfa` }),
    }),
    getResetPasswordUrl: build.query<
      GetResetPasswordUrlApiResponse,
      GetResetPasswordUrlApiArg
    >({
      query: () => ({ url: `/api/v1/users/reset-password` }),
    }),
    disableMfa: build.mutation<DisableMfaApiResponse, DisableMfaApiArg>({
      query: () => ({ url: `/api/v1/users/disable-mfa`, method: "POST" }),
    }),
    health: build.query<HealthApiResponse, HealthApiArg>({
      query: () => ({ url: `/api/v1/users/health` }),
    }),
    getViews: build.query<GetViewsApiResponse, GetViewsApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/views`,
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    createView: build.mutation<CreateViewApiResponse, CreateViewApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/views`,
        method: "PATCH",
        body: queryArg.createViewParams,
      }),
    }),
    getViewSummary: build.query<
      GetViewSummaryApiResponse,
      GetViewSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/views/summary`,
        headers: { organizationId: queryArg.organizationId },
        params: {
          collectionIds: queryArg.collectionIds,
          tagIds: queryArg.tagIds,
        },
      }),
    }),
    getView: build.query<GetViewApiResponse, GetViewApiArg>({
      query: (queryArg) => ({ url: `/api/v1/views/${queryArg.id}` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CreateAccountApiResponse = /** status 200 Ok */ AccountResponse;
export type CreateAccountApiArg = {
  createAccountParams: CreateAccountParams;
};
export type GetAccountsApiResponse = /** status 200 Ok */ AccountsResponse;
export type GetAccountsApiArg = {
  authClientId?: string;
};
export type UpdateAccountApiResponse = /** status 200 Ok */ AccountResponse;
export type UpdateAccountApiArg = {
  id: string;
  updateAccountParams: UpdateAccountParams;
};
export type DeleteAccountApiResponse = /** status 200  */
  | undefined
  | /** status 204 No content */ undefined;
export type DeleteAccountApiArg = {
  id: string;
};
export type GetAccountApiResponse = /** status 200 Ok */ AccountResponse;
export type GetAccountApiArg = {
  slug: string;
};
export type GetAssetsApiResponse = /** status 200 Ok */ GetAssetsResponse;
export type GetAssetsApiArg = {
  organizationId: string;
  tagIds?: string[];
  collectionIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
  includeRatingData?: boolean;
};
export type UpsertAssetApiResponse = /** status 200 Ok */ Asset;
export type UpsertAssetApiArg = {
  upsertAssetParams: UpsertAssetParams;
};
export type GetAssetApiResponse = /** status 200 Ok */ Asset;
export type GetAssetApiArg = {
  id: string;
};
export type GetAssetSystemUtilizationApiResponse =
  /** status 200 Ok */ GetAssetSystemUtilizationResponse;
export type GetAssetSystemUtilizationApiArg = {
  organizationId: string;
  id: string;
  timeHorizon?: DashboardTimeHorizon;
};
export type GetAssetsForBusinessEventImpactApiResponse =
  /** status 200 Ok */ GetAssetsForBusinessEventImpactResponse;
export type GetAssetsForBusinessEventImpactApiArg = {
  organizationId: string;
};
export type GetAssetSystemAttributesApiResponse =
  /** status 200 Ok */ GetAssetSystemAttributesResponse;
export type GetAssetSystemAttributesApiArg = {
  organizationId: string;
  id: string;
};
export type GetAssetWorkloadApiResponse =
  /** status 200 Ok */ GetAssetWorkloadResponse;
export type GetAssetWorkloadApiArg = {
  organizationId: string;
  id: string;
  timeHorizon?: DashboardTimeHorizon;
};
export type GetAssetCostAnalysisApiResponse =
  /** status 200 Ok */ GetCostAnalysisResponse;
export type GetAssetCostAnalysisApiArg = {
  organizationId: string;
  id: string;
  timeHorizon?: DashboardTimeHorizon;
};
export type GetAssetWorkloadGapAnalysisApiResponse =
  /** status 200 Ok */ GetAssetWorkloadGapAnalysisResponse;
export type GetAssetWorkloadGapAnalysisApiArg = {
  organizationId: string;
  id: string;
  timeHorizon?: DashboardTimeHorizon;
};
export type GetAssetSystemRatingsApiResponse =
  /** status 200 Ok */ RatingsOptionsResponse;
export type GetAssetSystemRatingsApiArg = {
  organizationId: string;
  id: string;
};
export type UpdateActiveStatusApiResponse = /** status 200  */
  | undefined
  | /** status 204 No content */ undefined;
export type UpdateActiveStatusApiArg = {
  updateActiveStatusParams: UpdateActiveStatusParams;
};
export type AuthHealthApiResponse = /** status 200 Ok */ HealthResponse;
export type AuthHealthApiArg = void;
export type GetTokenApiResponse = /** status 200 Ok */ GetTokenResponse;
export type GetTokenApiArg = {
  getTokenParams: GetTokenParams;
};
export type ListApiResponse = /** status 200 Ok */ MessageResponse;
export type ListApiArg = void;
export type GetBusinessEventsApiResponse =
  /** status 200 Ok */ GetBusinessEventsResponse;
export type GetBusinessEventsApiArg = {
  organizationId: string;
};
export type AddBusinessEventApiResponse = /** status 200 Ok */ BusinessEvent;
export type AddBusinessEventApiArg = {
  organizationId: string;
  addBusinessEventParams: AddBusinessEventParams;
};
export type GetBusinessEventApiResponse =
  /** status 200 Ok */ GetBusinessEventResponse;
export type GetBusinessEventApiArg = {
  id: string;
  organizationId: string;
};
export type UpdateBusinessEventApiResponse = /** status 200 Ok */ BusinessEvent;
export type UpdateBusinessEventApiArg = {
  id: string;
  organizationId: string;
  updateBusinessEventParams: UpdateBusinessEventParams;
};
export type UpsertBusinessEventImpactApiResponse =
  /** status 200 Ok */ BusinessEventImpact[];
export type UpsertBusinessEventImpactApiArg = {
  organizationId: string;
  upsertBusinessEventImpactParams: UpsertBusinessEventImpactParams;
};
export type GetBusinessEventsForTargetApiResponse =
  /** status 200 Ok */ BusinessEvent[];
export type GetBusinessEventsForTargetApiArg = {
  organizationId: string;
  id: string;
  startDate: string;
  endDate: string;
};
export type GetCollectionsApiResponse = /** status 200 Ok */ Collection[];
export type GetCollectionsApiArg = {
  organizationId: string;
};
export type CreateCollectionApiResponse = /** status 200 Ok */ Collection;
export type CreateCollectionApiArg = {
  createCollectionParams: CreateCollectionParams;
};
export type GetCollectionApiResponse = /** status 200 Ok */ Collection;
export type GetCollectionApiArg = {
  id: string;
};
export type GetCollectionSystemUtilizationApiResponse =
  /** status 200 Ok */ GetCollectionSystemUtilizationResponse;
export type GetCollectionSystemUtilizationApiArg = {
  organizationId: string;
  id: string;
  tagIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetCollectionWorkloadApiResponse =
  /** status 200 Ok */ GetAssetWorkloadResponse;
export type GetCollectionWorkloadApiArg = {
  organizationId: string;
  id: string;
  tagIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetCollectionSummaryApiResponse =
  /** status 200 Ok */ GetCollectionsSummaryResponse;
export type GetCollectionSummaryApiArg = {
  organizationId: string;
  id: string;
};
export type GetCollectionCostAnalysisApiResponse =
  /** status 200 Ok */ GetCostAnalysisResponse;
export type GetCollectionCostAnalysisApiArg = {
  organizationId: string;
  id: string;
  tagIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetCollectionWorkloadGapAnalysisApiResponse =
  /** status 200 Ok */ GetAssetWorkloadGapAnalysisResponse;
export type GetCollectionWorkloadGapAnalysisApiArg = {
  organizationId: string;
  id: string;
  tagIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type UpdateAwsIntegrationApiResponse = /** status 200 Ok */ Account;
export type UpdateAwsIntegrationApiArg = {
  organizationId: string;
  updateAwsIntegrationParams: UpdateAwsIntegrationParams;
};
export type SidebarDataApiResponse = /** status 200 Ok */ SidebarDataResponse;
export type SidebarDataApiArg = {
  organizationId: string;
};
export type UpdateFavoriteApiResponse = /** status 200 Ok */ MessageResponse;
export type UpdateFavoriteApiArg = {
  iSidebarItem: ISidebarItem;
};
export type GetOrganizationsApiResponse =
  /** status 200 Ok */ GetOrganizationsResponse;
export type GetOrganizationsApiArg = {
  accountSlug?: string;
};
export type GetOrganizationWorkloadApiResponse =
  /** status 200 Ok */ GetAssetWorkloadResponse;
export type GetOrganizationWorkloadApiArg = {
  organizationId: string;
  tagIds?: string[];
  collectionIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetOrganizationCostAnalysisApiResponse =
  /** status 200 Ok */ GetCostAnalysisResponse;
export type GetOrganizationCostAnalysisApiArg = {
  organizationId: string;
  tagIds?: string[];
  collectionIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetOrganizationWorkloadGapAnalysisApiResponse =
  /** status 200 Ok */ GetAssetWorkloadGapAnalysisResponse;
export type GetOrganizationWorkloadGapAnalysisApiArg = {
  organizationId: string;
  tagIds?: string[];
  collectionIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetOrganizationBusinessOverviewApiResponse =
  /** status 200 Ok */ RatingsOptionsResponse;
export type GetOrganizationBusinessOverviewApiArg = {
  organizationId: string;
  timeHorizon?: DashboardTimeHorizon;
};
export type GetOrganizationRatingsApiResponse =
  /** status 200 Ok */ RatingsOptionsResponse;
export type GetOrganizationRatingsApiArg = {
  organizationId: string;
  collectionIds?: string[];
  tagIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetOrganizationSystemUtilizationApiResponse =
  /** status 200 Ok */ GetViewSystemUtilizationResponse;
export type GetOrganizationSystemUtilizationApiArg = {
  organizationId: string;
  tagIds?: string[];
  collectionIds?: string[];
  timeHorizon?: DashboardTimeHorizon;
};
export type GetTagUploadUrlApiResponse =
  /** status 200 Ok */ S3SignedUrlResponse;
export type GetTagUploadUrlApiArg = {
  fileName: string;
};
export type GetTagDownloadUrlApiResponse = /** status 200 Ok */ string;
export type GetTagDownloadUrlApiArg = {
  fileName: string;
};
export type UserServiceApiResponse = /** status 204 No content */ undefined;
export type UserServiceApiArg = void;
export type AdminServiceApiResponse = /** status 204 No content */ undefined;
export type AdminServiceApiArg = void;
export type SystemAdminServiceApiResponse =
  /** status 204 No content */ undefined;
export type SystemAdminServiceApiArg = void;
export type ValidateTagFromFileApiResponse =
  /** status 200 Ok */ TagImportValidationResponse;
export type ValidateTagFromFileApiArg = {
  organizationId: string;
  fileName: string;
};
export type ImportTagFromFileApiResponse =
  /** status 200 Ok */ ImportTagsResponse;
export type ImportTagFromFileApiArg = {
  organizationId: string;
  fileName: string;
};
export type GetPastImportLogsApiResponse =
  /** status 200 Ok */ GetPastImportLogsResponse;
export type GetPastImportLogsApiArg = {
  organizationId: string;
};
export type BatchCreateTagApiResponse =
  /** status 200 Ok */ BatchCreateTagsResponse;
export type BatchCreateTagApiArg = {
  batchCreateTagsParams: BatchCreateTagsParams;
};
export type BatchAssignTagApiResponse =
  /** status 200 Ok */ BatchAssignTagsResponse;
export type BatchAssignTagApiArg = {
  batchAssignTagsParams: BatchAssignTagsParams;
};
export type AssignSystemTagApiResponse =
  /** status 200 Ok */ AssignSystemTagResponse;
export type AssignSystemTagApiArg = {
  assignSystemTagParams: AssignSystemTagParams;
};
export type GetTagsApiResponse = /** status 200 Ok */ GetTagsResponse;
export type GetTagsApiArg = {
  organizationId: string;
  assetId?: string;
  collectionIds?: string[];
  includeSystemTags?: boolean;
  tagIds?: string[];
  includeTagAssets?: boolean;
};
export type UpdateTagApiResponse = /** status 200 Ok */ UpdateTagResponse;
export type UpdateTagApiArg = {
  id: string;
  omitUpdateTagParamsId: OmitUpdateTagParamsId;
};
export type GetTagsEventsApiResponse = /** status 200 Ok */ TagEventResponse;
export type GetTagsEventsApiArg = {
  organizationId: string;
};
export type CreateUserApiResponse = /** status 200 Ok */ CreateUserResponse;
export type CreateUserApiArg = {
  createUserParams: CreateUserParams;
};
export type UpdateUserApiResponse = /** status 200 Ok */ UpdateUserResponse;
export type UpdateUserApiArg = {
  id: string;
  omitUpdateUserParamsUserId: OmitUpdateUserParamsUserId;
};
export type GetProfileApiResponse = /** status 200 Ok */ GetProfileResponse;
export type GetProfileApiArg = {
  organizationId?: string;
  accountSlug?: string;
};
export type GetUserByIdApiResponse =
  /** status 200 Ok */ PickUserOrUserIdOrEmailOrFirstNameOrLastNameOrCompanyNameOrTitleOrWorkPhoneOrMobilePhone;
export type GetUserByIdApiArg = {
  id: string;
};
export type AcceptanceStatusApiResponse =
  /** status 200 Ok */ AcceptanceStatusMessageResponse;
export type AcceptanceStatusApiArg = void;
export type UserAcceptanceDataApiResponse =
  /** status 200 Ok */ UserAcceptanceDataResponse;
export type UserAcceptanceDataApiArg = void;
export type SaveUserCustomDataApiResponse =
  /** status 200 Ok */ UserAcceptanceMessageResponse;
export type SaveUserCustomDataApiArg = void;
export type GetEnrollMfaUrlApiResponse =
  /** status 200 Ok */ GetEnrollMfaUrlResponse;
export type GetEnrollMfaUrlApiArg = void;
export type GetResetPasswordUrlApiResponse =
  /** status 200 Ok */ GetResetPasswordUrlResponse;
export type GetResetPasswordUrlApiArg = void;
export type DisableMfaApiResponse = /** status 204 No content */ undefined;
export type DisableMfaApiArg = void;
export type HealthApiResponse = /** status 200 Ok */
  | HealthResponse[]
  | /** status 204  */ HealthResponse;
export type HealthApiArg = void;
export type GetViewsApiResponse = /** status 200 Ok */ View[];
export type GetViewsApiArg = {
  organizationId: string;
};
export type CreateViewApiResponse = /** status 200 Ok */ View;
export type CreateViewApiArg = {
  createViewParams: CreateViewParams;
};
export type GetViewSummaryApiResponse =
  /** status 200 Ok */ GetCollectionsSummaryResponse;
export type GetViewSummaryApiArg = {
  organizationId: string;
  collectionIds?: string[];
  tagIds?: string[];
};
export type GetViewApiResponse = /** status 200 Ok */ View;
export type GetViewApiArg = {
  id: string;
};
export type TagStatus = "Active" | "Offline" | "Pending" | "Archived";
export type TagSystemType = "Active" | "Offline" | "Pending" | "Archived";
export type Tag = {
  id: string;
  organization?: Organization;
  key: string;
  value: string;
  description?: string;
  createdDate: string;
  updatedDate: string;
  status: TagStatus;
  assets: Asset[];
  tagId: string;
  organizationId?: string;
  systemTagType?: TagSystemType;
  assetCount: number;
  views?: View[];
};
export type View = {
  id: string;
  name: string;
  description?: string;
  navigateTo?: string;
  type?: string;
  isFavorite?: boolean;
  organizationId?: string;
  organization?: Organization;
  collections?: Collection[];
  tags?: Tag[];
  createdDate: string;
  updatedDate: string;
};
export type Collection = {
  id: string;
  name: string;
  description?: string;
  navigateTo: string;
  type: string;
  isFavorite?: boolean;
  organizationId?: string;
  organization?: Organization;
  assets?: Asset[];
  views?: View[];
  createdDate: string;
  updatedDate: string;
};
export type Asset = {
  id: string;
  name: string;
  description?: string;
  navigateTo?: string;
  type: string;
  isFavorite?: boolean;
  key: string;
  organizationId?: string;
  organization?: Organization;
  collectionId?: string;
  collection?: Collection;
  createdDate: string;
  updatedDate: string;
  lastReceivedDataDate: string;
  tags: Tag[];
};
export type ImpactType = "View" | "Collection" | "Asset" | "Tag";
export type BusinessEventImpact = {
  id: string;
  businessEventId?: string;
  businessEvent?: BusinessEvent;
  targetId: string;
  impactType: ImpactType;
};
export type BusinessEvent = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  thirdPartyReferenceId?: string;
  customerCategory?: string;
  description?: string;
  organizationId?: string;
  organization?: Organization;
  createdDate: string;
  updatedDate: string;
  userId: string;
  businessEventImpacts?: BusinessEventImpact[];
};
export type Actions = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export type EventsLog = {
  id: string;
  eventType?: Actions;
  message?: string;
  meta?: any;
  userName?: string;
  createdDate: string;
  organization?: Organization;
  organizationId?: string;
};
export type Organization = {
  id: string;
  account?: Account;
  assets?: Asset[];
  collections?: Collection[];
  views?: View[];
  businessEvents?: BusinessEvent[];
  tags: Tag[];
  eventsLog?: EventsLog[];
  name: string;
  accountId?: string;
  ownerId?: string;
  billingContactId?: string;
  technicalContactId?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  website?: string;
};
export type Account = {
  id: string;
  name: string;
  slug: string;
  bucket?: string;
  authClientId?: string;
  awsCrossAccountRoleArn?: string;
  ownerId?: string;
  billingContactId?: string;
  technicalContactId?: string;
  timeZone?: string;
  minWorkloadPercentThreshold: number;
  maxWorkloadPercentThreshold: number;
  organization: Organization[];
};
export type AccountResponse = {
  account: Account;
};
export type ErrorResponse = {
  message: string;
  stack?: string;
  errors?: string[];
  errorId?: string;
};
export type CreateAccountParams = {
  timeZone?: any;
  slug: string;
  name: string;
};
export type AccountsResponse = {
  accounts: Account[];
};
export type UpdateAccountParams = {
  maxWorkloadPercentThreshold?: number;
  minWorkloadPercentThreshold?: number;
  timeZone?: string;
  authClientId?: string;
  bucket?: string;
  website?: string;
  country?: string;
  zipCode?: string;
  state?: string;
  city?: string;
  addressLineTwo?: string;
  addressLineOne?: string;
  technicalContactId?: string;
  billingContactId?: string;
  ownerId?: string;
  name?: string;
};
export type AssetWcuStats = {
  wcup: number;
  median: number;
  max: number;
  min: number;
};
export type RatingDirection = "Up" | "Down" | "Dash" | "None";
export type RatingGrade = "A" | "B" | "C" | "D" | "F";
export type InfraRating = {
  direction?: RatingDirection;
  grade?: RatingGrade;
};
export type AssetRatingData = {
  status?: TagSystemType;
  wcu?: AssetWcuStats;
  rating?: InfraRating;
};
export type InventoryAsset = Asset & AssetRatingData;
export type GetAssetsResponse = InventoryAsset[];
export type DashboardTimeHorizon =
  | "Last Year"
  | "This Month"
  | "This Year"
  | "This Quarter"
  | "Last Quarter"
  | "Last Month"
  | "Last 30 Days"
  | "Last 60 Days"
  | "Last 90 Days";
export type UpsertAssetParams = {
  key?: string;
  collectionId: string;
  authAccountId?: string;
  organizationId?: string;
  name: string;
};
export type SystemUtilizationResult = {
  lastUpdated?: string;
  yAxisLabel: string;
  events: number[];
  dataPoints: string[];
  avg: number[];
  max: number[];
  min: number[];
  type: string;
};
export type GetAssetSystemUtilizationResponse = {
  data: SystemUtilizationResult[];
  id?: string;
};
export type TagForBusinessImpact = {
  assets: {
    name: string;
    id: string;
  }[];
  tagId: string;
  value: string;
  key: string;
  id: string;
};
export type CollectionForBusinessImpact = {
  assets: {
    name: string;
    id: string;
  }[];
  name: string;
  id: string;
};
export type GetAssetsForBusinessEventImpactResponse = {
  tags: TagForBusinessImpact[];
  collections: CollectionForBusinessImpact[];
};
export type AssetSystemAttribute = {
  date?: string;
  unit?: string;
  value?: string;
  name: string;
};
export type GetAssetSystemAttributesResponse = {
  attributes: AssetSystemAttribute[];
};
export type WcuAnalysisAggregationType = "Day" | "Monthly";
export type GetAssetWorkloadResponse = {
  lastUpdated?: string;
  eventDetails?: BusinessEvent[];
  eventsCount?: number[];
  aggregationType?: WcuAnalysisAggregationType;
  priorDate?: string[];
  priorCapacity?: (number | null)[];
  priorConsumption?: (number | null)[];
  date?: string[];
  capacity?: (number | null)[];
  consumption?: (number | null)[];
};
export type GetCostAnalysisResponse = {
  lastUpdated?: string;
  eventDetails?: BusinessEvent[];
  eventsCount?: number[];
  aggregationType?: WcuAnalysisAggregationType;
  priorDate?: string[];
  priorCost?: (number | null)[];
  currency?: (string | null)[];
  date?: string[];
  cost?: (number | null)[];
};
export type GetAssetWorkloadGapAnalysisResponse = {
  lastUpdated?: string;
  eventDetails?: BusinessEvent[];
  eventsCount?: number[];
  aggregationType?: WcuAnalysisAggregationType;
  maxUtilizationThreshold?: number;
  minUtilizationThreshold?: number;
  priorDate?: string[];
  date?: string[];
  priorPercentUtilization?: (number | null)[];
  percentUtilization?: (number | null)[];
};
export type Cost = {
  currency: string;
  cost: number;
};
export type RatingsOptions = {
  color?: string;
  value?: string | Cost;
  infoText?: string;
  label?: string;
  rating?: InfraRating;
};
export type RatingsOptionsResponse = {
  ratingsOptions: RatingsOptions[];
} & {
  lastUpdated?: string;
};
export type UpdateActiveStatusAccountAssets = {
  assetKeys: string[];
  accountId: string;
};
export type UpdateActiveStatusParams = {
  allAccountAssets: UpdateActiveStatusAccountAssets[];
};
export type HealthCheckStatus = "ok" | "error";
export type ServiceHealthResponse = {
  status: HealthCheckStatus;
  executionTime: number;
};
export type HealthResponse = {
  [key: string]: ServiceHealthResponse;
};
export type GetTokenResponse = {
  token?: string;
};
export type GetTokenParams = {
  password: string;
  email: string;
};
export type MessageResponse = {
  message: string;
};
export type PickBusinessEventExcludeKeyofBusinessEventOrganization = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  thirdPartyReferenceId?: string;
  customerCategory?: string;
  description?: string;
  organizationId?: string;
  createdDate: string;
  updatedDate: string;
  userId: string;
  businessEventImpacts?: BusinessEventImpact[];
};
export type OmitBusinessEventOrganization =
  PickBusinessEventExcludeKeyofBusinessEventOrganization;
export type BusinessEventWithImpact = OmitBusinessEventOrganization & {
  eventImpact: string;
};
export type GetBusinessEventsResponse = {
  lastUpdated?: string;
  businessEvents: BusinessEventWithImpact[];
};
export type AddBusinessEventParams = {
  description: string;
  endDate: string;
  startDate: string;
  name: string;
};
export type PickBusinessEventExcludeKeyofBusinessEventBusinessEventImpacts = {
  organization?: Organization;
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  thirdPartyReferenceId?: string;
  customerCategory?: string;
  description?: string;
  organizationId?: string;
  createdDate: string;
  updatedDate: string;
  userId: string;
};
export type OmitBusinessEventBusinessEventImpacts =
  PickBusinessEventExcludeKeyofBusinessEventBusinessEventImpacts;
export type GetBusinessEventResponse = OmitBusinessEventBusinessEventImpacts & {
  tags: Tag[];
  views: View[];
  collections: Collection[];
  assets: Asset[];
  createdByEmail: string;
};
export type UpdateBusinessEventParams = {
  description?: string;
  endDate?: string;
  startDate?: string;
  name?: string;
};
export type UpsertBusinessEventImpactParams = {
  tags: string[];
  collections: string[];
  assets: string[];
  businessEventId: string;
};
export type CreateCollectionParams = {
  description?: string;
  organizationId: string;
  name: string;
};
export type GetCollectionSystemUtilizationResponse = {
  data: SystemUtilizationResult[];
  id?: string;
};
export type GetCollectionsSummaryResponse = {
  lastUpdated?: string;
  currency?: string;
  offline?: number;
  active?: number;
  priorMonthCost?: Cost;
  monthlyCost?: Cost;
  virtualMachines?: number;
  totalAssets?: number;
};
export type UpdateAwsIntegrationParams = {
  awsCrossAccountRoleArn: string;
};
export type ISidebarItem = {
  id: string;
  name: string;
  description?: string;
  navigateTo?: string;
  type?: string;
  isFavorite?: boolean;
};
export type SidebarDataResponse = {
  favorites: ISidebarItem[];
  collections: ISidebarItem[];
  views: ISidebarItem[];
};
export type PickOrganizationExcludeKeyofOrganizationTagsOrEventsLog = {
  id: string;
  name: string;
  account?: Account;
  assets?: Asset[];
  collections?: Collection[];
  views?: View[];
  businessEvents?: BusinessEvent[];
  accountId?: string;
  ownerId?: string;
  billingContactId?: string;
  technicalContactId?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  website?: string;
};
export type OmitOrganizationTagsOrEventsLog =
  PickOrganizationExcludeKeyofOrganizationTagsOrEventsLog;
export type GetOrganizationsResponse = OmitOrganizationTagsOrEventsLog[];
export type GetViewSystemUtilizationResponse = {
  data: SystemUtilizationResult[];
};
export type S3SignedUrlResponse = {
  url: string;
  fileName: string;
};
export type ValidationErrorCode = 0 | 1 | 2 | 3;
export type TagValidationError = {
  lineNumber: number;
  dataField: string;
  data: string;
  error: string;
};
export type TagImportValidationResponse = {
  hasErrors: boolean;
  message: string;
  errorCode?: ValidationErrorCode;
  errors: TagValidationError[];
};
export type ImportTagsResponse = {
  successCount: number;
};
export type TagImportLogs = {
  id: string;
  fileName: string;
  s3FileName: string;
  importedRecordCount: number;
  importedDate: string;
  userId: string;
  userEmail: string;
  organizationId: string;
};
export type GetPastImportLogsResponse = {
  tagImportLogs: TagImportLogs[];
  lastUpdated?: string;
};
export type BatchCreateTagsResponse = {
  tags: Tag[];
};
export type CreateTagParams = {
  description?: string;
  value: string;
  key: string;
  [key: string]: any;
};
export type BatchCreateTagsParams = {
  tags: CreateTagParams[];
  organizationId: string;
};
export type BatchAssignTagsResponse = {
  assignmentEvent?: {
    events: string;
    tagId: string;
  }[];
  tags: Tag[];
};
export type AssignTagParams = {
  assetKeys: string[];
  tagKey: string;
};
export type BatchAssignTagsParams = {
  assignments: AssignTagParams[];
  organizationId: string;
};
export type AssignSystemTagResponse = {
  tag: Tag;
};
export type AssignSystemTagParams = {
  tagKey: string;
  assetId: string;
};
export type PickTagExcludeKeyofTagOrganization = {
  id: string;
  description?: string;
  organizationId?: string;
  createdDate: string;
  updatedDate: string;
  assets: Asset[];
  views?: View[];
  key: string;
  value: string;
  status: TagStatus;
  tagId: string;
  systemTagType?: TagSystemType;
  assetCount: number;
};
export type OmitTagOrganization = PickTagExcludeKeyofTagOrganization;
export type TagWithAssetCount = OmitTagOrganization & {
  assetCount: number;
};
export type GetTagsResponse = {
  lastUpdated?: string;
  tags: TagWithAssetCount[];
};
export type UpdateTagResponse = {
  updateEvent?: {
    isValueModified: boolean;
    isKeyModified: boolean;
  };
  assets: Asset[];
  status: TagStatus;
  updatedDate: string;
  createdDate: string;
  description?: string;
  value: string;
  key: string;
  organization?: Organization;
  id: string;
};
export type PickUpdateTagParamsExcludeKeyofUpdateTagParamsId = {
  description?: string;
  key: string;
  value: string;
};
export type OmitUpdateTagParamsId =
  PickUpdateTagParamsExcludeKeyofUpdateTagParamsId;
export type TagEventLog = {
  userId?: string;
  transaction?: string;
  time?: string;
  date?: string;
  tagId?: string;
};
export type TagEventResponse = {
  lastUpdated?: string;
  logs?: TagEventLog[];
};
export type UserOrganizationRole = {
  role: string;
  organizationId: string;
};
export type UserAccountRole = {
  role: string;
  accountSlug: string;
};
export type CreateUserResponse = {
  organizationRoles: UserOrganizationRole[];
  accountRoles: UserAccountRole[];
  mobilePhone?: string;
  workPhone?: string;
  title?: string;
  companyName?: string;
  lastName: string;
  firstName: string;
  email: string;
  userId: string;
};
export type CreateUserParams = {
  password?: string;
  organizationRoles: UserOrganizationRole[];
  accountRoles: UserAccountRole[];
  mobilePhone?: string;
  workPhone?: string;
  title?: string;
  companyName?: string;
  lastName: string;
  firstName: string;
  email: string;
};
export type UpdateUserResponse = {
  organizationRoles: UserOrganizationRole[];
  accountRoles: UserAccountRole[];
  mobilePhone?: string;
  workPhone?: string;
  title?: string;
  companyName?: string;
  lastName: string;
  firstName: string;
  email: string;
  userId: string;
};
export type PickUpdateUserParamsExcludeKeyofUpdateUserParamsUserId = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  title?: string;
  workPhone?: string;
  mobilePhone?: string;
  accountRoles?: UserAccountRole[];
  organizationRoles?: UserOrganizationRole[];
};
export type OmitUpdateUserParamsUserId =
  PickUpdateUserParamsExcludeKeyofUpdateUserParamsUserId;
export type User = {
  mfaEnabled: boolean;
  mobilePhone?: string;
  workPhone?: string;
  title?: string;
  companyName?: string;
  lastName: string;
  firstName: string;
  email: string;
  userId: string;
};
export type PickAccountExcludeKeyofAccountOrganization = {
  id: string;
  name: string;
  ownerId?: string;
  billingContactId?: string;
  technicalContactId?: string;
  slug: string;
  bucket?: string;
  authClientId?: string;
  awsCrossAccountRoleArn?: string;
  timeZone?: string;
  minWorkloadPercentThreshold: number;
  maxWorkloadPercentThreshold: number;
};
export type OmitAccountOrganization =
  PickAccountExcludeKeyofAccountOrganization;
export type PickOrganizationExcludeKeyofOrganizationTags = {
  id: string;
  name: string;
  eventsLog?: EventsLog[];
  account?: Account;
  assets?: Asset[];
  collections?: Collection[];
  views?: View[];
  businessEvents?: BusinessEvent[];
  accountId?: string;
  ownerId?: string;
  billingContactId?: string;
  technicalContactId?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
  website?: string;
};
export type OmitOrganizationTags = PickOrganizationExcludeKeyofOrganizationTags;
export type GetUserAccountsResponse = (OmitAccountOrganization & {
  organization: OmitOrganizationTags[];
})[];
export type UserFrontendAccountPermissions = {
  showAccountLogs: boolean;
  viewAccountSupport: boolean;
};
export type UserFrontendOrganizationPermissions = {
  showAdmin: boolean;
};
export type UserFrontendPermissions = UserFrontendAccountPermissions &
  UserFrontendOrganizationPermissions;
export type GetProfileResponse = User & {
  accounts: GetUserAccountsResponse;
  permissions: UserFrontendPermissions;
  roles: string[];
};
export type PickUserOrUserIdOrEmailOrFirstNameOrLastNameOrCompanyNameOrTitleOrWorkPhoneOrMobilePhone =
  {
    userId: string;
    firstName: string;
    lastName: string;
    companyName?: string;
    title?: string;
    workPhone?: string;
    mobilePhone?: string;
    email: string;
  };
export type AcceptanceStatusMessageResponse = {
  userMetaData: any;
};
export type UserAcceptanceDataResponse = {
  lastUpdated: string;
  infraMeterText: string;
  termsOfServiceText: string;
};
export type UserAcceptanceMessageResponse = {
  message: string;
};
export type GetEnrollMfaUrlResponse = {
  enrollUrl: string;
};
export type GetResetPasswordUrlResponse = {
  resetPasswordUrl: string;
};
export type CreateViewParams = {
  id?: string;
  tagIds?: string[];
  collectionIds?: string[];
  organizationId: string;
  description?: string;
  name: string;
};
export const {
  useCreateAccountMutation,
  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useGetAssetsQuery,
  useLazyGetAssetsQuery,
  useUpsertAssetMutation,
  useGetAssetQuery,
  useLazyGetAssetQuery,
  useGetAssetSystemUtilizationQuery,
  useLazyGetAssetSystemUtilizationQuery,
  useGetAssetsForBusinessEventImpactQuery,
  useLazyGetAssetsForBusinessEventImpactQuery,
  useGetAssetSystemAttributesQuery,
  useLazyGetAssetSystemAttributesQuery,
  useGetAssetWorkloadQuery,
  useLazyGetAssetWorkloadQuery,
  useGetAssetCostAnalysisQuery,
  useLazyGetAssetCostAnalysisQuery,
  useGetAssetWorkloadGapAnalysisQuery,
  useLazyGetAssetWorkloadGapAnalysisQuery,
  useGetAssetSystemRatingsQuery,
  useLazyGetAssetSystemRatingsQuery,
  useUpdateActiveStatusMutation,
  useAuthHealthQuery,
  useLazyAuthHealthQuery,
  useGetTokenMutation,
  useListQuery,
  useLazyListQuery,
  useGetBusinessEventsQuery,
  useLazyGetBusinessEventsQuery,
  useAddBusinessEventMutation,
  useGetBusinessEventQuery,
  useLazyGetBusinessEventQuery,
  useUpdateBusinessEventMutation,
  useUpsertBusinessEventImpactMutation,
  useGetBusinessEventsForTargetQuery,
  useLazyGetBusinessEventsForTargetQuery,
  useGetCollectionsQuery,
  useLazyGetCollectionsQuery,
  useCreateCollectionMutation,
  useGetCollectionQuery,
  useLazyGetCollectionQuery,
  useGetCollectionSystemUtilizationQuery,
  useLazyGetCollectionSystemUtilizationQuery,
  useGetCollectionWorkloadQuery,
  useLazyGetCollectionWorkloadQuery,
  useGetCollectionSummaryQuery,
  useLazyGetCollectionSummaryQuery,
  useGetCollectionCostAnalysisQuery,
  useLazyGetCollectionCostAnalysisQuery,
  useGetCollectionWorkloadGapAnalysisQuery,
  useLazyGetCollectionWorkloadGapAnalysisQuery,
  useUpdateAwsIntegrationMutation,
  useSidebarDataQuery,
  useLazySidebarDataQuery,
  useUpdateFavoriteMutation,
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useGetOrganizationWorkloadQuery,
  useLazyGetOrganizationWorkloadQuery,
  useGetOrganizationCostAnalysisQuery,
  useLazyGetOrganizationCostAnalysisQuery,
  useGetOrganizationWorkloadGapAnalysisQuery,
  useLazyGetOrganizationWorkloadGapAnalysisQuery,
  useGetOrganizationBusinessOverviewQuery,
  useLazyGetOrganizationBusinessOverviewQuery,
  useGetOrganizationRatingsQuery,
  useLazyGetOrganizationRatingsQuery,
  useGetOrganizationSystemUtilizationQuery,
  useLazyGetOrganizationSystemUtilizationQuery,
  useGetTagUploadUrlQuery,
  useLazyGetTagUploadUrlQuery,
  useGetTagDownloadUrlQuery,
  useLazyGetTagDownloadUrlQuery,
  useUserServiceQuery,
  useLazyUserServiceQuery,
  useAdminServiceQuery,
  useLazyAdminServiceQuery,
  useSystemAdminServiceQuery,
  useLazySystemAdminServiceQuery,
  useValidateTagFromFileQuery,
  useLazyValidateTagFromFileQuery,
  useImportTagFromFileMutation,
  useGetPastImportLogsQuery,
  useLazyGetPastImportLogsQuery,
  useBatchCreateTagMutation,
  useBatchAssignTagMutation,
  useAssignSystemTagMutation,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useUpdateTagMutation,
  useGetTagsEventsQuery,
  useLazyGetTagsEventsQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useAcceptanceStatusQuery,
  useLazyAcceptanceStatusQuery,
  useUserAcceptanceDataQuery,
  useLazyUserAcceptanceDataQuery,
  useSaveUserCustomDataMutation,
  useGetEnrollMfaUrlQuery,
  useLazyGetEnrollMfaUrlQuery,
  useGetResetPasswordUrlQuery,
  useLazyGetResetPasswordUrlQuery,
  useDisableMfaMutation,
  useHealthQuery,
  useLazyHealthQuery,
  useGetViewsQuery,
  useLazyGetViewsQuery,
  useCreateViewMutation,
  useGetViewSummaryQuery,
  useLazyGetViewSummaryQuery,
  useGetViewQuery,
  useLazyGetViewQuery,
} = injectedRtkApi;
