import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { View } from "./generatedApi";

export interface SaveViewState {
  showSaveView: boolean;
  initialView: Partial<View>;
  title: string;
}

const initialState: SaveViewState = {
  showSaveView: false,
  initialView: { name: "", description: "" },
  title: "",
};

const SaveViewSlice = createSlice({
  name: "SaveViewSlice",
  initialState,
  reducers: {
    setShowSaveView: (state, action: PayloadAction<boolean>) => {
      state.showSaveView = action.payload;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setView: (state, action: PayloadAction<Partial<View>>) => {
      state.initialView = action.payload;
    },
  },
});

export const { setShowSaveView, setView, setTitle } = SaveViewSlice.actions;
export default SaveViewSlice.reducer;
