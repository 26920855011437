import { ReactComponent as DownArrow } from "@material-design-icons/svg/outlined/arrow_downward.svg";
import { ReactComponent as UpArrow } from "@material-design-icons/svg/outlined/arrow_upward.svg";
import { ReactComponent as Manuals } from "@material-design-icons/svg/outlined/article.svg";
import { ReactComponent as Money } from "@material-design-icons/svg/outlined/attach_money.svg";
import { ReactComponent as CancelCircle } from "@material-design-icons/svg/outlined/cancel.svg";
import { ReactComponent as CheckCircle } from "@material-design-icons/svg/outlined/check_circle.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as Clear } from "@material-design-icons/svg/outlined/clear.svg";
import { ReactComponent as Delete } from "@material-design-icons/svg/outlined/delete.svg";
import { ReactComponent as Description } from "@material-design-icons/svg/outlined/description.svg";
import { ReactComponent as Dns } from "@material-design-icons/svg/outlined/dns.svg";
import { ReactComponent as drag } from "@material-design-icons/svg/outlined/drag_indicator.svg";
import { ReactComponent as Edit } from "@material-design-icons/svg/outlined/edit.svg";
import { ReactComponent as Event } from "@material-design-icons/svg/outlined/event.svg";
import { ReactComponent as Expanded } from "@material-design-icons/svg/outlined/expand_more.svg";
import { ReactComponent as Feed } from "@material-design-icons/svg/outlined/feed.svg";
import { ReactComponent as Download } from "@material-design-icons/svg/outlined/file_download.svg";
import { ReactComponent as FullScreen } from "@material-design-icons/svg/outlined/fullscreen.svg";
import { ReactComponent as History } from "@material-design-icons/svg/outlined/history.svg";
import { ReactComponent as HorizontalRule } from "@material-design-icons/svg/outlined/horizontal_rule.svg";
import { ReactComponent as Info } from "@material-design-icons/svg/outlined/info.svg";
import { ReactComponent as ArrowLeft } from "@material-design-icons/svg/outlined/keyboard_arrow_left.svg";
import { ReactComponent as Memory } from "@material-design-icons/svg/outlined/memory.svg";
import { ReactComponent as more } from "@material-design-icons/svg/outlined/more_horiz.svg";
import { ReactComponent as People } from "@material-design-icons/svg/outlined/people.svg";
import { ReactComponent as Rule } from "@material-design-icons/svg/outlined/rule.svg";
import { ReactComponent as Security } from "@material-design-icons/svg/outlined/security.svg";
import { ReactComponent as Sell } from "@material-design-icons/svg/outlined/sell.svg";
import { ReactComponent as Star } from "@material-design-icons/svg/outlined/star.svg";
import { ReactComponent as StarOutline } from "@material-design-icons/svg/outlined/star_outline.svg";
import { ReactComponent as Summarize } from "@material-design-icons/svg/outlined/summarize.svg";
import { ReactComponent as Timeline } from "@material-design-icons/svg/outlined/timeline.svg";
import { ReactComponent as Hub } from "@material-design-icons/svg/outlined/hub.svg";
import { ReactComponent as Explore } from "@material-design-icons/svg/outlined/explore.svg";
import { ReactComponent as Warning } from "@material-design-icons/svg/outlined/warning.svg";

export const SummarizeIcon = Summarize;
export const PeopleIcon = People;
export const Securityicon = Security;
export const DescriptionIcon = Description;
export const ManualsIcon = Manuals;
export const DnsIcon = Dns;
export const TimelineIcon = Timeline;
export const EventIcon = Event;
export const SellIcon = Sell;
export const RuleIcon = Rule;
export const MemoryIcon = Memory;
export const MoneyIcon = Money;
export const UpArrowIcon = UpArrow;
export const DownArrowIcon = DownArrow;
export const HorizontalRuleIcon = HorizontalRule;
export const InfoIcon = Info;
export const ArrowLeftIcon = ArrowLeft;
export const StarIcon = Star;
export const DeleteIcon = Delete;
export const CheckCircleIcon = CheckCircle;
export const CancelCircleIcon = CancelCircle;
export const StarOutlineIcon = StarOutline;
export const DragIcon = drag;
export const DownloadIcon = Download;
export const FullScreenIcon = FullScreen;
export const MoreIcon = more;
export const EditIcon = Edit;
export const FeedIcon = Feed;
export const ClearIcon = Clear;
export const HistoryIcon = History;
export const HubIcon = Hub;
export const ExploreIcon = Explore;
export const ExpandedIcon = Expanded;
export const ChevronRightIcon = ChevronRight;
export const WarningIcon = Warning;
