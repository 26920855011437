import { CSpinner } from "@coreui/react-pro";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  i18nTextKey: string;
}

const SpinnerWithText: React.FC<Props> = ({ i18nTextKey }) => {
  const { t } = useTranslation();
  return (
    <div className="widget-frame-wrapper" style={{ border: "none" }}>
      <div className="widget-frame-loading">
        <CSpinner className="green-spinner" />
        {`${t(i18nTextKey)}...`}
      </div>
    </div>
  );
};

export default SpinnerWithText;
