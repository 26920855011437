import {
  CancelCircleIcon,
  CheckCircleIcon,
  WarningIcon,
} from "@/components/Icons";
import {
  GetMessage,
  GetOperations,
  ProcessErrorMessage,
  ProcessSuccessMessage,
  renderComponentToString,
} from "@/components/toast/ToastMessages";
import { deepCheckIsEmptyObject, isEmpty } from "@/utils";
import { arrayEqual } from "@/utils/array";
import { getEndpoints, getPageName } from "@/utils/widgetQueries";
import {
  createAction,
  createListenerMiddleware,
  isAnyOf,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RootState } from ".";
import {
  DashboardState,
  applyFilter,
  setCollections,
  setQueryCacheKeys,
  setQueryEndpoints,
  setTags,
  toggleSaveViewButton,
} from "./dashboard";
import { ErrorResponse } from "./generatedApi";
export const listenerMiddleware = createListenerMiddleware();
const rejectedQuery = createAction<any>("api/executeQuery/rejected");
const fulfilledAction = createAction<any>("api/executeMutation/fulfilled");
const rejectedAction = createAction<any>("api/executeMutation/rejected");
const queryFulfilled = "api/executeQuery/fulfilled";
const subscriptionUpdated = "api/internalSubscriptions/subscriptionsUpdated";
const checkCache = (params: {
  dashboardState: DashboardState;
  selectedOrganization: { id: string };
}) => {
  const { dashboardState, selectedOrganization } = params;
  const queryCacheKeys = dashboardState.queryCacheKeys;
  const filterParams = {
    collectionIds: dashboardState.collections.map((a) => a.value),
    tagIds: dashboardState.tags.map((a) => a.value),
    timeHorizon: dashboardState.timeHorizon,
    organizationId: selectedOrganization.id,
  };
  const condition = (param: any) =>
    (param?.tagIds ? arrayEqual(param.tagIds, filterParams.tagIds) : true) &&
    filterParams.timeHorizon == param.timeHorizon &&
    filterParams.organizationId == param.organizationId &&
    (param?.collection
      ? arrayEqual(param.collectionIds, filterParams.collectionIds)
      : true);

  return queryCacheKeys?.every((key) => {
    const param = JSON.parse(
      key.substring(key.indexOf("(") + 1, key.lastIndexOf(")"))
    );
    return condition(param);
  });
};

const checkEndpoint = (queryEndpoints: any) => {
  return queryEndpoints.every((endpoints: any) => {
    return endpoints.value;
  });
};
listenerMiddleware.startListening({
  matcher: isAnyOf(rejectedAction, fulfilledAction),
  effect: async (action, listenerApi) => {
    try {
      const actionMetadata = action;
      const { payload, meta } = actionMetadata;
      const endpointName = meta?.arg?.endpointName;
      const { request, response } = meta.baseQueryMeta;
      const methodName = request?.method;
      const statusCode = response.status;

      let operation = GetOperations(methodName);
      // First step, get toast message using status code and endpoint

      let message = GetMessage(statusCode, endpointName);
      listenerApi.cancelActiveListeners();
      if (isRejectedWithValue(action)) {
        message = ProcessErrorMessage(payload, message, operation);
        toast.error(message, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          icon: CancelCircleIcon,
        });
      } else {
        message = ProcessSuccessMessage(
          payload,
          methodName,
          operation,
          message
        );
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          icon: CheckCircleIcon,
        });
      }
    } catch (ex) {
      console.log("Error occurred with toast listenerMiddleware", action, ex);
    }
  },
});
/**
 * Listen to api calls and filter out non data integrations
 */
listenerMiddleware.startListening({
  predicate: (action) => {
    const actionMetadata = action;
    const { meta } = actionMetadata;

    return (
      action.type === queryFulfilled &&
      meta?.baseQueryMeta?.response?.headers.has("Data-Integration")
    );
  },
  async effect(action, { getState, dispatch, cancelActiveListeners }) {
    const actionMetadata = action;
    const { payload, meta } = actionMetadata;
    const queryCacheKey = meta?.arg?.queryCacheKey;
    const recordIsEmpty = deepCheckIsEmptyObject(payload);
    dispatch(
      setQueryEndpoints({ key: meta.arg.endpointName, value: recordIsEmpty })
    ); // log the endpoint
    if (queryCacheKey && recordIsEmpty) {
      dispatch(setQueryCacheKeys(queryCacheKey)); // log the cache key to rebuild later
    }
    const currentState = getState() as RootState;
    const dashboardState = currentState.dashboard;
    const queryEndpoints = dashboardState.queryEndpoints;
    const page = getPageName(meta.arg.endpointName);
    let endpoints = new Set<string>();
    //check if dashboard is view dashboard
    const isViewPage = !(
      isEmpty(dashboardState.defaultTags) ||
      isEmpty(dashboardState.defaultCollections)
    );

    endpoints = isViewPage
      ? new Set(...[getEndpoints("View")])
      : new Set(...[getEndpoints(page)]);
    // check if the endpoints logged is equal to expected api calls. If true, then this listner is the end of the whole requests
    if (queryEndpoints.length == endpoints.size) {
      const showToast = checkEndpoint(queryEndpoints); // show toast by checking all endpoints logged
      dispatch(setQueryEndpoints({})); // reset the api endpoints logs
      toast.clearWaitingQueue();
      toast.dismiss("warning");
      cancelActiveListeners();
      showToast &&
        toast.warning(renderComponentToString("common.noDataAvailable"), {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          icon: WarningIcon,
          containerId: "empty-data",
          toastId: "warning",
        });
    }
  },
});
/**
 * Listen to fetches from cache and filter out non data integrations
 */
listenerMiddleware.startListening({
  predicate: (action, currentState) => {
    const getState = currentState as RootState;
    const dashboardState = getState.dashboard;
    return (
      action.type === subscriptionUpdated &&
      isEmpty(dashboardState.queryEndpoints) &&
      !isEmpty(dashboardState.queryCacheKeys)
    );
  },
  async effect(action, { getState, cancelActiveListeners }) {
    const currentState = getState() as RootState;
    const dashboardState = currentState.dashboard;
    const recordIsEmpty = checkCache({
      dashboardState,
      selectedOrganization: currentState.selectedOrganization,
    });
    toast.clearWaitingQueue();
    toast.dismiss("warning");
    cancelActiveListeners();
    recordIsEmpty &&
      toast.warning(renderComponentToString("common.noDataAvailable"), {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        icon: WarningIcon,
        containerId: "empty-data",
        toastId: "warning",
      });
  },
});
/**
 * Listenser to reset all filters back to default. In use in View Dasboard
 */
listenerMiddleware.startListening({
  actionCreator: createAction<any>("dashboard/resetAllFilters"),
  effect: (action, listenerApi) => {
    const dashboardState = (listenerApi?.getState() as RootState).dashboard;

    listenerApi.dispatch(
      setTags({
        selectedTags: dashboardState.defaultTags?.map(
          (item: { tagId: any; id: any }) => ({
            label: item.tagId,
            value: item.id,
          })
        ),
      })
    );
    listenerApi.dispatch(
      setCollections({
        selectedCollections: dashboardState.defaultCollections?.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      })
    );
    listenerApi.dispatch(applyFilter());
    listenerApi.dispatch(toggleSaveViewButton());
  },
});

/**
 * Listens for failed GET queries and pops an error code + toast
 */
listenerMiddleware.startListening({
  matcher: isAnyOf(rejectedQuery),
  effect: async (action) => {
    const errorContent = action?.payload?.data as ErrorResponse;
    const { meta: metadata } = action;
    const { response } = metadata.baseQueryMeta;
    const statusCode = response.status;

    // dont pop anything for 401 errors, we handle those in the baseApi
    if (statusCode === 401) {
      return;
    }

    // for network timeouts (408), show a different error message
    const errorMessage =
      statusCode === 408
        ? renderComponentToString("toastMessages.networkError")
        : renderComponentToString("toastMessages.queryError");
    const errorSuffix = isEmpty(errorContent?.errorId)
      ? ""
      : ` ${renderComponentToString("toastMessages.errorId")}: ${
          errorContent?.errorId
        }`;

    const fullMessage = errorMessage + errorSuffix;

    toast.dismiss("warning");
    toast.error(fullMessage, {
      position: "top-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      theme: "light",
      icon: CancelCircleIcon,
    });
    toast.clearWaitingQueue();
  },
});
export default listenerMiddleware.middleware;
