import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import sidebar from "./sidebar";
import selectedOrganization from "./selectedOrganization";
import selectedAccount from "./selectedAccount";
import dashboard from "./dashboard";
import assetWcu from "./assetWcu";
import { api } from "./generatedApi";
import listenerMiddleware from "./listenerMiddleware ";
import saveView from "./saveView";

// Create the root reducer separately so we can extract the RootState type
const reducer = combineReducers({
  sidebar,
  selectedOrganization,
  selectedAccount,
  dashboard,
  saveView,
  assetWcu,
  [api.reducerPath]: api.reducer,
});

export const createStore = () =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware).concat(listenerMiddleware),
  });
const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
