import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  BaseQueryApi,
  retry,
} from "@reduxjs/toolkit/query/react";
import { RootState } from ".";
import { LOGIN_REDIRECT_URL } from "@/constants/urls";

const MAX_TIMEOUT_RETRIES = 7;

const customBaseQuery = (options: Parameters<typeof fetchBaseQuery>[0]) => {
  const baseQuery = retry(fetchBaseQuery(options), {
    retryCondition: (error, _args, extraArgs) => {
      if (extraArgs.attempt > MAX_TIMEOUT_RETRIES) {
        return false;
      }

      return error.status === 504 || error.status === "TIMEOUT_ERROR";
    },
  });
  return async (endpointDefinition: FetchArgs, api: BaseQueryApi) => {
    // Safari limitation: Safari + Firefox does not properly encode the "|" variable, correct for that
    endpointDefinition.url = endpointDefinition.url.replace("|", "%7C");

    // Proceed with the original request
    const result = await baseQuery(endpointDefinition, api, {});

    // If unauthorized error, log the user out
    if (result.error && result.error.status === 401) {
      window.location.replace(LOGIN_REDIRECT_URL);
    }

    return result;
  };
};
// Empty API used for rtk-query openapi code generation
export const emptyApi = createApi({
  baseQuery: customBaseQuery({
    baseUrl: "/",
    prepareHeaders: (headers, { getState }) => {
      const organizationId = (getState() as RootState).selectedOrganization.id;
      // If we have a organization set in state, Then pass it in the header.
      if (organizationId) {
        headers.set("organizationId", `${organizationId}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
