import store from "@/store";
import {
  DashboardTimeHorizon,
  api,
  useGetAssetCostAnalysisQuery,
  useGetAssetSystemUtilizationQuery,
  useGetAssetWorkloadGapAnalysisQuery,
  useGetAssetWorkloadQuery,
  useGetCollectionCostAnalysisQuery,
  useGetCollectionSystemUtilizationQuery,
  useGetCollectionWorkloadGapAnalysisQuery,
  useGetCollectionWorkloadQuery,
  useGetOrganizationCostAnalysisQuery,
  useGetOrganizationSystemUtilizationQuery,
  useGetOrganizationWorkloadGapAnalysisQuery,
  useGetOrganizationWorkloadQuery,
} from "@/store/generatedApi";
import { isEmpty } from ".";
import { includeString } from "./string";
export enum FilterableWidgetTargetType {
  ASSET = "asset",
  COLLECTION = "collection",
  VIEW = "view",
  ORGANIZATION = "organization",
}

type FilterableWidgetQueryParams = {
  targetType: FilterableWidgetTargetType;
  organizationId: string;
  targetId: string;
  timeHorizon: DashboardTimeHorizon;
  tagIds?: string[];
  collectionIds?: string[];
  applyFilter?: boolean;
};

/**
 * Gets the right rtx query for the target to get the correct workload data
 * Extracted to reduce complexity
 * @param params
 * @returns
 */
export const getWorkloadWidgetQuery = (params: FilterableWidgetQueryParams) => {
  const {
    targetType,
    targetId,
    organizationId,
    timeHorizon,
    tagIds,
    collectionIds,
    applyFilter,
  } = params;
  const skip = isEmpty(organizationId) || isEmpty(targetId);

  switch (targetType) {
    case FilterableWidgetTargetType.COLLECTION:
      return useGetCollectionWorkloadQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
        },
        { skip }
      );
    case FilterableWidgetTargetType.VIEW:
    // Intentional fall through to ORGANIZATION
    case FilterableWidgetTargetType.ORGANIZATION:
      return useGetOrganizationWorkloadQuery(
        {
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
          collectionIds: isEmpty(collectionIds) ? undefined : collectionIds,
        },
        { skip: skip && !applyFilter }
      );
    case FilterableWidgetTargetType.ASSET:
    // Intentional fall through to default
    default:
      return useGetAssetWorkloadQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
        },
        { skip }
      );
  }
};

/**
 * Gets the right rtx query for the target to get the correct workload cost analysis data
 * @param params
 * @returns
 */
export const getCostAnalysisWidgetQuery = (
  params: FilterableWidgetQueryParams
) => {
  const {
    targetType,
    targetId,
    organizationId,
    timeHorizon,
    tagIds,
    collectionIds,
    applyFilter,
  } = params;

  const skip = isEmpty(organizationId) || isEmpty(targetId);
  switch (targetType) {
    case FilterableWidgetTargetType.COLLECTION:
      return useGetCollectionCostAnalysisQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
        },
        { skip }
      );
    case FilterableWidgetTargetType.VIEW:
    // Intentional fall through to ORGANIZATION
    case FilterableWidgetTargetType.ORGANIZATION:
      return useGetOrganizationCostAnalysisQuery(
        {
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
          collectionIds: isEmpty(collectionIds) ? undefined : collectionIds,
        },
        { skip: skip && !applyFilter }
      );
    case FilterableWidgetTargetType.ASSET:
    // Intentional fall through to default
    default:
      return useGetAssetCostAnalysisQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
        },
        { skip }
      );
  }
};

/**
 * Gets the right rtx query for the target to get the correct workload gap analysis data
 * @param params
 * @returns
 */
export const getWorkloadGapAnalysisWidgetQuery = (
  params: FilterableWidgetQueryParams
) => {
  const {
    targetType,
    targetId,
    organizationId,
    timeHorizon,
    tagIds,
    collectionIds,
    applyFilter,
  } = params;

  const skip = isEmpty(organizationId) || isEmpty(targetId);
  switch (targetType) {
    case FilterableWidgetTargetType.COLLECTION:
      return useGetCollectionWorkloadGapAnalysisQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
        },
        { skip }
      );
    case FilterableWidgetTargetType.VIEW:
    // Intentional fall through to ORGANIZATION
    case FilterableWidgetTargetType.ORGANIZATION:
      return useGetOrganizationWorkloadGapAnalysisQuery(
        {
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
          collectionIds: isEmpty(collectionIds) ? undefined : collectionIds,
        },
        { skip: skip && !applyFilter }
      );

    case FilterableWidgetTargetType.ASSET:
    // Intentional fall through to default
    default:
      return useGetAssetWorkloadGapAnalysisQuery(
        {
          id: targetId,
          organizationId,
          timeHorizon,
        },
        { skip }
      );
  }
};
export const getSystemUtilizationWidgetQuery = (
  params: FilterableWidgetQueryParams
) => {
  const {
    targetType,
    targetId,
    organizationId,
    timeHorizon,
    tagIds,
    collectionIds,
    applyFilter,
  } = params;

  switch (targetType) {
    case FilterableWidgetTargetType.COLLECTION:
      return useGetCollectionSystemUtilizationQuery(
        {
          organizationId,
          id: targetId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
        },
        { skip: isEmpty(organizationId) }
      );
    case FilterableWidgetTargetType.VIEW:
      return useGetOrganizationSystemUtilizationQuery(
        {
          organizationId,
          timeHorizon,
          tagIds: isEmpty(tagIds) ? undefined : tagIds,
          collectionIds: isEmpty(collectionIds) ? undefined : collectionIds,
        },
        { skip: isEmpty(organizationId) && !applyFilter }
      );
    case FilterableWidgetTargetType.ASSET:
    default:
      return useGetAssetSystemUtilizationQuery(
        {
          organizationId,
          id: targetId,
          timeHorizon,
        },
        { skip: isEmpty(organizationId) }
      );
  }
};

export const getQueryTarget = (
  assetId?: string,
  collectionId?: string,
  viewId?: string,
  organizationId?: string
): { targetId: string; targetType: FilterableWidgetTargetType } => {
  const targetId = assetId ?? collectionId ?? viewId ?? organizationId ?? "";
  let targetType;

  switch (targetId) {
    case collectionId:
      targetType = FilterableWidgetTargetType.COLLECTION;
      break;
    case viewId:
      targetType = FilterableWidgetTargetType.VIEW;
      break;
    case organizationId:
      targetType = FilterableWidgetTargetType.ORGANIZATION;
      break;
    case assetId:
    // Intentional fall through to default
    default:
      targetType = FilterableWidgetTargetType.ASSET;
  }

  return { targetId, targetType };
};

export const getFilterParams = () => {
  const dashboardState = store.getState().dashboard;
  const tagIds = dashboardState.tags?.map((item) => item.value);
  const collectionIds = dashboardState.collections?.map((item) => item.value);
  const applyFilter = dashboardState.applyFilter;

  return { tagIds, collectionIds, applyFilter };
};

const testRegex = (pattern: string | RegExp, source: string): boolean => {
  const regex = new RegExp(pattern);
  return regex.test(source);
};

export const getEndpoints = (page: string) => {
  const apiEndpoints = Object.keys(api.endpoints);
  let pageEndpoints = [];
  for (let endpoint of apiEndpoints) {
    switch (page) {
      case "Asset": {
        const condition = endpoint === "getAssetWorkload";
        condition && pageEndpoints.push(endpoint);
        break;
      }
      case "Collection": {
        const condition =
          includeString(endpoint, "Collection", "Workload") ||
          includeString(endpoint, "Collection", "CostAnalysis");
        condition && pageEndpoints.push(endpoint);
        break;
      }
      case "Organization": {
        const condition =
          includeString(endpoint, "Organization", "Workload") ||
          includeString(endpoint, "Organization", "CostAnalysis");
        condition && pageEndpoints.push(endpoint);
        break;
      }
      case "View": {
        const condition =
          includeString(endpoint, "Organization", "Workload") ||
          includeString(endpoint, "Organization", "CostAnalysis");
        condition && pageEndpoints.push(endpoint);
        break;
      }
    }
  }
  return pageEndpoints;
};

export const getPageName = (endpointName: string) => {
  if (endpointName?.toLowerCase().includes("collection")) return "Collection";
  else if (endpointName?.toLowerCase().includes("organization"))
    return "Organization";
  else if (endpointName?.toLowerCase().includes("asset")) return "Asset";
  return "";
};
