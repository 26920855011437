import { isEmpty } from "@/utils";
import { JSONPath } from "jsonpath-plus";
import { renderToStaticMarkup } from "react-dom/server";
import { Trans } from "react-i18next";

const errorKeyWords = {
  exist: "toastMessages.entityExistErrorMessage",
  duplicate: "toastMessages.entityExistErrorMessage",
};
const customToastMessages = {
  createView: {
    "2**": "toastMessages.template.createViewSuccessMessage",
    "!2**": "toastMessages.template.createViewErrorMessage",
  },
  updateFavorite: {
    "2**": "toastMessages.template.updateFavoriteSuccessMessage",
  },
  upsertBusinessEventImpact: {
    "2**": "toastMessages.successMessage",
  },
  updateTag: {
    "2**": "toastMessages.template.updateTagSuccessMessage",
  },
  batchCreateTag: {
    "2**": "toastMessages.template.batchCreateTagSuccessMessage",
  },
};
export const renderComponentToString = (i18nString: string) => {
  return renderToStaticMarkup(<Trans i18nKey={i18nString} />);
};
export const GetMessage = (
  statusCode: string,
  endpointName: string,
  toastMessages: {} = customToastMessages
): string => {
  const template = Reflect.get(toastMessages, endpointName);
  let result = "";

  if (!isEmpty(template)) {
    const templateKeys = Object.keys(template);

    for (const keyToMatch of templateKeys) {
      const isMatch = IsMatch(statusCode, keyToMatch);
      if (isMatch) {
        result = renderComponentToString(`${template[keyToMatch]}`);
        break;
      }
    }
  }
  return result;
};
const IsMatch = (key: string, keyToMatch: string): boolean => {
  let isMatch = true;
  let returnValue = false;
  keyToMatch = keyToMatch.toString();
  let IsNotValue = keyToMatch.startsWith("!");
  key = key.toString();
  for (let index = 0; index < key.length; index++) {
    let keyValue = key[index];
    let wildCardValue = keyToMatch[index];

    if (keyValue != wildCardValue) {
      if (wildCardValue === "*") {
        //Wildcard, so continue
        continue;
      }

      if (IsNotValue) {
        break;
      }

      //Not a match
      isMatch = false;
      break;
    }
  }

  if (isMatch) {
    returnValue = true;
  }

  return returnValue;
};
export const GetMessageFromTemplate = (
  i18nString: string,
  operation: string,
  data: any
): string => {
  let found = [];
  const match = i18nString.split("{{");
  for (let i = 1; i < match.length; i++) {
    found.push(match[i].split("}}")[0]);
  }

  found.forEach((template) => {
    if (!isEmpty(template) && !isEmpty(data)) {
      if (template === "operation") {
        i18nString = i18nString.replace(`{{${template}}}`, operation);
      } else {
        const fieldToReplace = JSONPath({
          path: template,
          json: data ?? [],
        }).toString();
        i18nString = i18nString.replace(`{{${template}}}`, fieldToReplace);
      }
    }
  });

  return i18nString;
};

export const ProcessSuccessMessage = (
  payload: any,
  methodName: string,
  operation: string,
  message: string
): string => {
  //if no response data use default success message
  if (!isEmpty(payload)) {
    const id = Reflect.get(
      Array.isArray(payload) ? payload?.[0] : payload,
      "id"
    );
    if (id !== "" && methodName.toUpperCase() == "PATCH") {
      operation = "updated";
    }
    message = isEmpty(message)
      ? renderComponentToString("toastMessages.successMessageWithEntity")
      : message;

    message = GetMessageFromTemplate(message, operation, payload);
  }
  message = isEmpty(message)
    ? renderComponentToString("toastMessages.successMessage")
    : message;
  return message;
};

export const ProcessErrorMessage = (
  payload: any,
  message: string,
  operation: string
): string => {
  const { data } = payload;
  const errorMessage = data?.message;
  let errorTemplate = "";
  //check for keywords

  const keyWords = Object.keys(errorKeyWords);
  for (let keyWord of keyWords) {
    if (errorMessage.includes(keyWord)) {
      errorTemplate = Reflect.get(errorKeyWords, keyWord);
      break;
    }
  }
  message = !isEmpty(errorTemplate) ? "" : message;
  const errorSuffix = isEmpty(data?.errorId)
    ? ""
    : ` ${renderComponentToString("toastMessages.errorId")}: ${data?.errorId}`;
  errorTemplate = isEmpty(errorTemplate)
    ? "toastMessages.unknownErrorMessage"
    : errorTemplate;
  if (message == "") {
    message = GetMessageFromTemplate(
      renderComponentToString(errorTemplate),
      operation,
      payload ?? []
    );
  }
  return message + errorSuffix;
};

export const GetOperations = (methodName: string): string => {
  switch (methodName.toUpperCase()) {
    case "POST":
      return "Saved";
    case "DELETE":
      return "Deleted";
    case "PATCH":
      return "Updated";
    default:
      return "";
  }
};
