import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLocalStorage,
  isEmpty,
  SELECTED_ACCOUNT_KEY,
  SELECTED_ORGANIZATION_KEY,
} from "../utils/utils";

import { Account, api, OmitOrganizationTags } from "./generatedApi";

const cleanState = {
  id: "",
  accountId: "",
  name: "",
  ownerId: "",
  billingContactId: "",
  technicalContactId: "",
  addressLineOne: "",
  addressLineTwo: "",
  city: "",
  state: "",
  zipCode: "",
  country: "",
  website: "",
  account: {} as Account,
};

const localOrganization = getLocalStorage(SELECTED_ORGANIZATION_KEY);
const localAccount = getLocalStorage(SELECTED_ACCOUNT_KEY);
const hasLocalSelections = localAccount && localOrganization;

const selectedOrganizationSlice = createSlice({
  name: "selectedOrganization",
  initialState: hasLocalSelections ? localOrganization : cleanState,
  reducers: {
    setSelectedOrganization: (
      state,
      action: PayloadAction<OmitOrganizationTags>
    ) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.accountId = action.payload.account?.id ?? "";
      state.ownerId = action.payload.ownerId ?? "";
      state.billingContactId = action.payload.billingContactId ?? "";
      state.technicalContactId = action.payload.technicalContactId ?? "";
      state.addressLineOne = action.payload.addressLineOne ?? "";
      state.addressLineTwo = action.payload.addressLineTwo ?? "";
      state.city = action.payload.city ?? "";
      state.state = action.payload.state ?? "";
      state.zipCode = action.payload.zipCode ?? "";
      state.country = action.payload.country ?? "";
      state.website = action.payload.website ?? "";
      state.account = action.payload.account as Account;
    },
    resetSelectedOrganization: () => cleanState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getProfile.matchFulfilled,
      (state, action) => {
        const accounts = action?.payload?.accounts;
        if (
          state.id === cleanState.id &&
          !isEmpty(accounts) &&
          !isEmpty(accounts[0].organization)
        ) {
          const { organization: organizations } = accounts[0];
          state.name = organizations[0].name;
          state.id = organizations[0].id;
          state.accountId = organizations[0].accountId ?? "";
          state.ownerId = organizations[0].ownerId ?? "";
          state.billingContactId = organizations[0].billingContactId ?? "";
          state.technicalContactId = organizations[0].technicalContactId ?? "";
          state.addressLineOne = organizations[0].addressLineOne ?? "";
          state.addressLineTwo = organizations[0].addressLineTwo ?? "";
          state.city = organizations[0].city ?? "";
          state.state = organizations[0].state ?? "";
          state.zipCode = organizations[0].zipCode ?? "";
          state.country = organizations[0].country ?? "";
          state.website = organizations[0].website ?? "";
          state.account = organizations[0].account as Account;
        }
      }
    );
  },
});

export const { setSelectedOrganization, resetSelectedOrganization } =
  selectedOrganizationSlice.actions;
export default selectedOrganizationSlice.reducer;
