import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLocalStorage,
  isEmpty,
  SELECTED_ACCOUNT_KEY,
  SELECTED_ORGANIZATION_KEY,
} from "../utils/utils";

import { Account, api } from "./generatedApi";

const cleanState = {
  id: "",
  name: "",
  slug: "",
  bucket: undefined,
  authClientId: undefined,
  awsCrossAccountRoleArn: undefined,
  ownerId: undefined,
  billingContactId: undefined,
  technicalContactId: undefined,
  timeZone: undefined,
  organization: [],
  minWorkloadPercentThreshold: 40,
  maxWorkloadPercentThreshold: 60,
} as Account;

const localOrganization = getLocalStorage(SELECTED_ORGANIZATION_KEY);
const localAccount = getLocalStorage(SELECTED_ACCOUNT_KEY);
const hasLocalSelections = localAccount && localOrganization;

const selectedAccountSlice = createSlice({
  name: "selectedAccount",
  initialState: hasLocalSelections ? localAccount : cleanState,
  reducers: {
    setSelectedAccount: (state, action: PayloadAction<Account>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.slug = action.payload.slug;
      state.bucket = action.payload.bucket;
      state.authClientId = action.payload.authClientId;
      state.awsCrossAccountRoleArn = action.payload.awsCrossAccountRoleArn;
      state.ownerId = action.payload.ownerId;
      state.billingContactId = action.payload.billingContactId;
      state.technicalContactId = action.payload.technicalContactId;
      state.timeZone = action.payload.timeZone;
      state.organization = action.payload.organization;
    },
    resetSelectedAccount: () => cleanState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getProfile.matchFulfilled,
      (state, action) => {
        const accounts = action?.payload?.accounts;
        if (state.id === cleanState.id && !isEmpty(accounts)) {
          state.id = accounts[0].id;
          state.name = accounts[0].name;
          state.slug = accounts[0].slug;
          state.bucket = accounts[0].bucket;
          state.authClientId = accounts[0].authClientId;
          state.awsCrossAccountRoleArn = accounts[0].awsCrossAccountRoleArn;
          state.ownerId = accounts[0].ownerId;
          state.billingContactId = accounts[0].billingContactId;
          state.technicalContactId = accounts[0].technicalContactId;
          state.timeZone = accounts[0].timeZone;
          state.organization = accounts[0].organization;
        }
      }
    );
  },
});

export const { setSelectedAccount, resetSelectedAccount } =
  selectedAccountSlice.actions;
export default selectedAccountSlice.reducer;
