import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISidebarItem } from "./generatedApi";

export interface SidebarState {
  sidebarShow: boolean;
  showAdmin: boolean;
  selectedItem: Partial<ISidebarItem>;
}
const initialState: SidebarState = {
  sidebarShow: false,
  showAdmin: false,
  selectedItem: {},
};
const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarShow = !state.sidebarShow;
    },
    setSidebarShow: (state, action: PayloadAction<boolean>) => {
      state.sidebarShow = action.payload;
    },
    setShowAdmin: (state, action: PayloadAction<boolean>) => {
      state.showAdmin = action.payload;
    },
    setHeaderTitle: (state, action: PayloadAction<Partial<ISidebarItem>>) => {
      state.selectedItem = action.payload;
    },
  },
});

export const { toggleSidebar, setSidebarShow, setShowAdmin, setHeaderTitle } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
